import axios from "axios";
import { usePrefs } from "../services/prefs";
import { useTenancy } from "../services/tenancy";
export const useApiTenant = (clientId) => {
    var _a;
    const tenancy = useTenancy();
    const prefs = usePrefs();
    const api = axios.create({
        baseURL: process.env.API_URL + "/api/tenant/" + (clientId ? clientId : (_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.id),
    });
    const source = axios.CancelToken.source();
    const cancel = () => source.cancel();
    const isCancel = error => axios.isCancel(error);
    api.interceptors.response.use(response => response, error => {
        if (error.response &&
            (error.response.status === 401 || error.response.status === 403) &&
            window.location.pathname !== "/login") {
            prefs.setCentralToken(null);
            prefs.setTenantToken(null);
            window.location.replace("/login");
        }
        throw error;
    });
    // Users
    const GET_users = (page_size, page, order_by, order_direction, search, search_by, filter_role, filter_group_ids, include_parent_groups) => {
        return api.get("/users", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
                search_by,
                filter_role,
                filter_group_ids,
                include_parent_groups,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_user = (first_name, last_name, email, username, is_enabled, role, password, phone, avatar, custom_fields) => {
        let formData = new FormData();
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("email", email);
        formData.append("username", username);
        formData.append("role", role);
        if (password)
            formData.append("password", password);
        if (phone)
            formData.append("phone", phone);
        if (avatar)
            formData.append("avatar", avatar);
        if (custom_fields)
            formData.append("custom_fields", JSON.stringify(custom_fields));
        return api.post("/users", formData, {
            cancelToken: source.token,
            headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_user = (id) => {
        return api.get("/users/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_user = (id, first_name, last_name, email, username, is_enabled, role, old_password, password, phone, avatar, custom_fields) => {
        let formData = new FormData();
        if (first_name)
            formData.append("first_name", first_name);
        if (last_name)
            formData.append("last_name", last_name);
        if (role)
            formData.append("role", role);
        if (old_password)
            formData.append("old_password", old_password);
        if (password)
            formData.append("password", password);
        formData.append("email", email ? email : "");
        formData.append("username", username ? username : "");
        formData.append("phone", phone ? phone : "");
        formData.append("avatar", avatar ? avatar : "");
        if (custom_fields)
            formData.append("custom_fields", JSON.stringify(custom_fields));
        return api.post("/users/" + id, formData, {
            cancelToken: source.token,
            headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_user = (id) => {
        return api.delete("/users/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_currentUser = () => api.get("/users/current", {
        cancelToken: source.token,
        headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
            Authorization: `Bearer ${prefs.tenantToken}`,
        })),
    });
    // Groups
    const GET_groups = (order_by, order_direction, include_ticket_count, ticket_count_campaign_id_filter, current_user_groups_only) => {
        return api.get("/groups", {
            params: {
                order_by,
                order_direction,
                include_ticket_count,
                ticket_count_campaign_id_filter,
                current_user_groups_only,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_group = (name, custom_fields, parent_group_id) => {
        return api.post("/groups", {
            name,
            custom_fields,
            parent_group_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_group = (id) => {
        return api.get("/groups/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_group = (id, name, custom_fields, parent_group_id) => {
        return api.post("/groups/" + id, {
            id,
            name,
            custom_fields,
            parent_group_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_group = (id) => {
        return api.delete("/groups/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_groupUsers = (id, page_size, page, order_by, order_direction, search, groups, include_parent_groups) => {
        if (id) {
            return api.get("/groups/" + id + "/users", {
                params: {
                    page_size,
                    page,
                    order_by,
                    order_direction,
                    search,
                    include_parent_groups,
                },
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
        else {
            return api.get("/groups/users", {
                params: {
                    page_size,
                    page,
                    order_by,
                    order_direction,
                    search,
                    groups,
                    include_parent_groups,
                },
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
    };
    const GET_groupAttachableUsers = (id, search, search_by) => {
        return api.get("/groups/" + id + "/attachable-users", {
            params: {
                page_size: 10000,
                page: 1,
                search,
                search_by,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_userOnGroup = (groupId, userId) => {
        return api.post("/groups/" + groupId + "/attach-user/" + userId, {}, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_userFromGroup = (groupId, userId) => {
        return api.post("/groups/" + groupId + "/detach-user/" + userId, {}, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Phone Countries
    const GET_phoneCountries = (page_size, page, order_by, order_direction, search) => {
        return api.get("/phone-countries", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_phoneCountry = (name, code, phone) => {
        return api.post("/phone-countries", {
            name,
            code,
            phone,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_phoneCountry = (id) => {
        return api.get("/phone-countries/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_phoneCountry = (id) => {
        return api.delete("/phone-countries/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Phone Areas
    const GET_phoneAreas = (page_size, page, order_by, order_direction, search) => {
        return api.get("/phone-areas", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_phoneArea = (name, phone, phone_country_id, validation_rule, validation_message) => {
        return api.post("/phone-areas", {
            name,
            phone,
            phone_country_id,
            validation_rule,
            validation_message,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_phoneArea = (id) => {
        return api.get("/phone-areas/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_phoneArea = (id, name, phone, phone_country_id, validation_rule, validation_message) => {
        return api.post("/phone-areas/" + id, {
            name,
            phone,
            phone_country_id,
            validation_rule,
            validation_message,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_phoneArea = (id) => {
        return api.delete("/phone-areas/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Custom Fields
    const GET_customFields = (table_name, page_size, page, order_by, order_direction, search, campaign_step_id, shared, has_shared_parent, filterable_only, filter_campaign_id, filter_visible_only) => {
        return api.get("/custom-fields", {
            params: {
                table_name,
                page_size,
                page,
                order_by,
                order_direction,
                search,
                campaign_step_id,
                shared,
                has_shared_parent,
                filterable_only,
                filter_campaign_id,
                filter_visible_only,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_customField = (table_name, field_name, field_type, required, text_items, campaign_step_foreign_key, shared, shared_parent_foreign_key, autofill, step, max_value, min_value, icon, empty_icon, min_label, max_label, date_time_type, filterable, has_metrics, not_editable, field_caption, is_public, value_number_title, target_number_title, result_number_title, change_number_title, number_unit, group_ids, allow_multiple_files, max_file_count) => {
        if (field_type === "radio_image") {
            const formData = new FormData();
            formData.append("table_name", table_name);
            formData.append("field_name", field_name);
            formData.append("field_type", field_type);
            formData.append("required", required ? "1" : "0");
            formData.append("image_text_items", JSON.stringify(text_items));
            formData.append("autofill", JSON.stringify(autofill));
            formData.append("is_public", JSON.stringify(is_public));
            formData.append("group_ids", JSON.stringify(group_ids));
            if (filterable !== undefined)
                formData.append("filterable", JSON.stringify(filterable));
            if (not_editable !== undefined)
                formData.append("not_editable", JSON.stringify(not_editable));
            if (has_metrics !== undefined)
                formData.append("has_metrics", JSON.stringify(has_metrics));
            if (campaign_step_foreign_key)
                formData.append("campaign_step_foreign_key", campaign_step_foreign_key);
            if (shared)
                formData.append("shared", shared ? "1" : "0");
            if (shared_parent_foreign_key)
                formData.append("shared_parent_foreign_key", shared_parent_foreign_key);
            if (field_caption)
                formData.append("field_caption", field_caption);
            text_items === null || text_items === void 0 ? void 0 : text_items.forEach(item => {
                var _a;
                if ((_a = item.image) === null || _a === void 0 ? void 0 : _a.toString().startsWith("http")) {
                    formData.append("images[" + item.id + "]", JSON.stringify(null));
                }
                else {
                    formData.append("images[" + item.id + "]", item.image);
                }
            });
            return api.post("/custom-fields", formData, {
                cancelToken: source.token,
                headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
        else {
            return api.post("/custom-fields", {
                table_name,
                field_name,
                field_type,
                required,
                text_items,
                campaign_step_foreign_key,
                shared,
                shared_parent_foreign_key,
                autofill,
                step,
                max_value,
                min_value,
                icon,
                empty_icon,
                min_label,
                max_label,
                date_time_type,
                filterable,
                has_metrics,
                not_editable,
                field_caption,
                is_public,
                value_number_title,
                target_number_title,
                result_number_title,
                change_number_title,
                number_unit,
                group_ids,
                allow_multiple_files,
                max_file_count,
            }, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
    };
    const GET_customField = (id) => {
        return api.get("/custom-fields/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_customField = (id, table_name, field_name, field_type, required, text_items, campaign_step_foreign_key, shared, autofill, step, max_value, min_value, icon, empty_icon, min_label, max_label, date_time_type, filterable, has_metrics, not_editable, field_caption, is_public, value_number_title, target_number_title, result_number_title, change_number_title, number_unit, group_ids, allow_multiple_files, max_file_count) => {
        if (field_type === "radio_image") {
            const formData = new FormData();
            formData.append("table_name", table_name);
            formData.append("field_name", field_name);
            formData.append("field_type", field_type);
            formData.append("required", required ? "1" : "0");
            formData.append("image_text_items", JSON.stringify(text_items));
            formData.append("shared", JSON.stringify(shared));
            formData.append("autofill", JSON.stringify(autofill));
            formData.append("is_public", JSON.stringify(is_public));
            formData.append("group_ids", JSON.stringify(group_ids));
            if (field_caption)
                formData.append("field_caption", field_caption);
            if (filterable !== undefined)
                formData.append("filterable", JSON.stringify(filterable));
            if (not_editable !== undefined)
                formData.append("not_editable", JSON.stringify(not_editable));
            if (has_metrics !== undefined)
                formData.append("has_metrics", JSON.stringify(has_metrics));
            if (campaign_step_foreign_key)
                formData.append("campaign_step_foreign_key", JSON.stringify(campaign_step_foreign_key));
            text_items === null || text_items === void 0 ? void 0 : text_items.forEach(item => {
                var _a;
                if (!((_a = item.image) === null || _a === void 0 ? void 0 : _a.toString().startsWith("http")) && item.id) {
                    formData.append("image" + item.id, item.image);
                }
                else if (!item.id) {
                    formData.append("images[]", item.image);
                }
            });
            return api.post("/custom-fields/" + id, formData, {
                cancelToken: source.token,
                headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
        else {
            return api.post("/custom-fields/" + id, {
                table_name,
                field_name,
                field_type,
                required,
                text_items,
                campaign_step_foreign_key,
                shared,
                autofill,
                step,
                max_value,
                min_value,
                icon,
                empty_icon,
                min_label,
                max_label,
                date_time_type,
                filterable,
                has_metrics,
                not_editable,
                field_caption,
                is_public,
                value_number_title,
                target_number_title,
                result_number_title,
                change_number_title,
                number_unit,
                group_ids,
                allow_multiple_files,
                max_file_count,
            }, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
    };
    const DELETE_customField = (id) => {
        return api.delete("/custom-fields/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const REORDER_customFields = (table_name, custom_fields) => {
        return api.post("/custom-fields/reorder", {
            table_name,
            custom_fields,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Contacts
    const GET_contacts = (page_size, page, order_by, order_direction, search, search_by, filter_type, filter_phone_country_id, filter_phone_area_id, fields, count_only, filter_custom_field_ids, filter_custom_field_value_ids, display_contact_history, phone_search_exact_match) => {
        return api.get("/contacts", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
                search_by,
                filter_type,
                filter_phone_country_id,
                filter_phone_area_id,
                fields,
                count_only,
                filter_custom_field_ids,
                filter_custom_field_value_ids,
                display_contact_history,
                phone_search_exact_match,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_contact = (first_name, last_name, company, type, address, oib, phones, emails, custom_fields, is_anonymous, campaign_id, campaign_slug, client_id) => {
        // @ts-ignore
        if (address === null || address === "")
            address = [];
        return api.post("/contacts", {
            first_name,
            last_name,
            company,
            type,
            address,
            oib,
            phones,
            emails,
            custom_fields,
            is_anonymous,
            campaign_id,
            campaign_slug,
            client_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_contact = (id) => {
        return api.get("/contacts/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_contact = (id, first_name, last_name, company, type, address, oib, phones, emails, custom_fields, is_anonymous, campaign_id) => {
        // @ts-ignore
        if (address === null || address === "")
            address = [];
        return api.post("/contacts/" + id, {
            first_name,
            last_name,
            company,
            type,
            address,
            oib,
            phones,
            emails,
            custom_fields,
            is_anonymous,
            campaign_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_contact = (id) => {
        return api.delete("/contacts/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const EXPORT_contacts = (order_by, order_direction, search, search_by, filter_type, filter_phone_country_id, filter_phone_area_id, export_fields_filter, export_custom_fields_filter) => {
        return api.get("/contacts/export", {
            params: {
                order_by,
                order_direction,
                search,
                search_by,
                filter_type,
                filter_phone_country_id,
                filter_phone_area_id,
                export_fields_filter,
                export_custom_fields_filter,
            },
            responseType: "arraybuffer",
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const IMPORT_contacts = (xls_file, update_existing, allow_duplicates) => {
        let formData = new FormData();
        formData.append("xls_file", xls_file);
        if (update_existing !== undefined)
            formData.append("update_existing", JSON.stringify(update_existing));
        if (allow_duplicates !== undefined)
            formData.append("allow_duplicates", JSON.stringify(allow_duplicates));
        return api.post("/contacts/import", formData, {
            cancelToken: source.token,
            headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const IMPORT_contactsSample = () => {
        return api.get("/contacts/import-example", {
            responseType: "arraybuffer",
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_contactHistory = (id, contact_id) => {
        return api.delete("/contacts/" + contact_id + "/contact-history/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Logs
    const GET_logs = (page_size, page, order_by, order_direction, search, filter_causer_id, filter_subject_type) => {
        return api.get("/activity-logs", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
                filter_causer_id,
                filter_subject_type,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Knowledge
    const GET_knowledgeAll = (page_size, page, order_by, order_direction, search, is_email_template) => {
        return api.get("/knowledge", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
                is_email_template,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_knowledge = (title, caption, content, is_email_template) => {
        return api.post("/knowledge", {
            title,
            caption,
            content,
            is_email_template,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_knowledge = (id) => {
        return api.get("/knowledge/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_knowledge = (id, title, caption, content, is_email_template) => {
        return api.post("/knowledge/" + id, {
            title,
            caption,
            content,
            is_email_template,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_knowledge = (id) => {
        return api.delete("/knowledge/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Campaigns
    const GET_campaigns = (page_size, page, filter_types, search, order_by, order_direction, filter_active, filter_for_current_user, include_ticket_count_campaign_ids, hide_single_identical_campaign_groups) => {
        return api.get("/campaigns", {
            params: {
                page_size,
                page,
                filter_types,
                search,
                order_by,
                order_direction,
                filter_active,
                filter_for_current_user,
                include_ticket_count_campaign_ids,
                hide_single_identical_campaign_groups,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_campaign = (name, type, ticket_statuses, ticket_priorities, ticket_activities, active, language, description, queues, instructions, contract_campaign_id, group_ids, field_options, custom_field_options, slug, farewell_message, allow_multiple_survey_submits, require_captcha_on_survey, activity_group_ids, details_group_ids) => {
        return api.post("/campaigns", {
            name,
            type,
            ticket_statuses,
            ticket_priorities,
            ticket_activities,
            active,
            language,
            description,
            queues,
            instructions,
            contract_campaign_id,
            group_ids,
            field_options,
            custom_field_options,
            slug,
            farewell_message,
            allow_multiple_survey_submits,
            require_captcha_on_survey,
            activity_group_ids,
            details_group_ids,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaign = (id, include_ticket_count, ticket_count_group_id_filter, include_column_display_options_for_all_roles) => {
        return api.get("/campaigns/" + id, {
            params: {
                include_ticket_count,
                ticket_count_group_id_filter,
                include_column_display_options_for_all_roles,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_campaign = (id, name, type, ticket_statuses, ticket_priorities, ticket_activities, active, language, description, queues, instructions, contract_campaign_id, group_ids, field_options, custom_field_options, slug, farewell_message, allow_multiple_survey_submits, require_captcha_on_survey, activity_group_ids, details_group_ids) => {
        return api.post("/campaigns/" + id, {
            name,
            type,
            ticket_statuses,
            ticket_priorities,
            ticket_activities,
            active,
            language,
            description,
            queues,
            instructions,
            contract_campaign_id,
            group_ids,
            field_options,
            custom_field_options,
            slug,
            farewell_message,
            allow_multiple_survey_submits,
            require_captcha_on_survey,
            activity_group_ids,
            details_group_ids,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_campaign = (id) => {
        return api.delete("/campaigns/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignFilterableFields = (campaign_id) => {
        return api.get("/campaigns/" + campaign_id + "/filterable-fields", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignFilterableChoices = (campaign_id) => {
        return api.get("/campaigns/" + campaign_id + "/filterable-choices", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignExportChoiceOptions = (campaign_id) => {
        return api.get("/campaigns/" + campaign_id + "/export-choice-options", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignExportFieldOptions = (campaign_id) => {
        return api.get("/campaigns/" + campaign_id + "/export-custom-field-options", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignCustomFieldMetrics = (campaign) => {
        return api.get("/campaigns/" + campaign + "/custom-field-metrics", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignChoiceMetrics = (campaign) => {
        return api.get("/campaigns/" + campaign + "/choice-metrics", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Campaign steps
    const GET_campaignSteps = (campaign_id, page_size, page, order_by, order_direction, search, include_autofill_data, contact_id) => {
        return api.get("/campaigns/" + campaign_id + "/steps", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
                include_autofill_data,
                contact_id,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_campaignStep = (campaign_id, name, type, parent_campaign_step_id, image, content, caption, groupsIds, sharedCampaignStepId, knowledge_ids, filterable, has_metrics, not_editable) => {
        let formData = new FormData();
        formData.append("campaign_id", campaign_id);
        formData.append("name", name);
        formData.append("type", type);
        if (parent_campaign_step_id)
            formData.append("parent_campaign_step_id", parent_campaign_step_id);
        if (image)
            formData.append("image", image);
        if (content)
            formData.append("content", content);
        if (caption)
            formData.append("caption", caption);
        if (groupsIds)
            formData.append("group_ids", JSON.stringify(groupsIds));
        if (sharedCampaignStepId)
            formData.append("shared_campaign_step_id", sharedCampaignStepId);
        if (knowledge_ids)
            formData.append("knowledge_ids", JSON.stringify(knowledge_ids));
        if (filterable !== undefined)
            formData.append("filterable", JSON.stringify(filterable));
        if (has_metrics !== undefined)
            formData.append("has_metrics", JSON.stringify(has_metrics));
        if (not_editable !== undefined)
            formData.append("not_editable", JSON.stringify(not_editable));
        return api.post("/campaigns/" + campaign_id + "/steps", formData, {
            cancelToken: source.token,
            headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignStep = (id, campaign_id) => {
        return api.get("/campaigns/" + campaign_id + "/steps/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_campaignStep = (campaign_id, id, name, type, image, content, caption, groupsIds, sharedCampaignStepId, knowledge_ids, filterable, has_metrics, not_editable) => {
        let formData = new FormData();
        formData.append("campaign_id", campaign_id);
        formData.append("name", name);
        formData.append("type", type);
        formData.append("caption", caption ? caption : "");
        formData.append("content", content ? content : "");
        formData.append("knowledge_ids", JSON.stringify(knowledge_ids ? knowledge_ids : []));
        formData.append("group_ids", JSON.stringify(groupsIds ? groupsIds : []));
        if (image !== undefined)
            formData.append("image", image ? image : "");
        if (type === "choice")
            formData.append("shared_campaign_step_id", sharedCampaignStepId ? sharedCampaignStepId : JSON.stringify(null));
        if (filterable !== undefined)
            formData.append("filterable", JSON.stringify(filterable));
        if (has_metrics !== undefined)
            formData.append("has_metrics", JSON.stringify(has_metrics));
        if (not_editable !== undefined)
            formData.append("not_editable", JSON.stringify(not_editable));
        return api.post("/campaigns/" + campaign_id + "/steps/" + id, formData, {
            cancelToken: source.token,
            headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_campaignStep = (id, campaign_id) => {
        return api.delete("/campaigns/" + campaign_id + "/steps/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignsChoiceFields = (page_size, page, order_by, order_direction, search) => {
        return api.get("/campaigns/shared-choices", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_campaignsChoiceField = (name, not_editable, filterable, has_metrics) => {
        return api.post("/campaigns/shared-choices", {
            name,
            not_editable,
            filterable,
            has_metrics,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_campaignsChoiceField = (id) => {
        return api.get("/campaigns/shared-choices/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_campaignsChoiceField = (id, name, not_editable, filterable, has_metrics) => {
        return api.post("/campaigns/shared-choices/" + id, {
            name,
            not_editable,
            filterable,
            has_metrics,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_campaignChoiceField = (id) => {
        return api.delete("/campaigns/shared-choices/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Audiences
    const GET_audiences = (campaign_id, page_size, page, search, order_by, order_direction) => {
        return api.get("/campaigns/" + campaign_id + "/audiences", {
            params: {
                page_size,
                page,
                search,
                order_by,
                order_direction,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_audience = (campaign_id, name, description, active) => {
        return api.post("/campaigns/" + campaign_id + "/audiences", {
            name,
            description,
            active,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_audience = (id, campaign_id, page_size, page, search, order_by, order_direction) => {
        return api.get("/campaigns/" + campaign_id + "/audiences/" + id, {
            params: {
                page_size,
                page,
                search,
                order_by,
                order_direction,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_audience = (campaign_id, id, name, description, active) => {
        return api.post("/campaigns/" + campaign_id + "/audiences/" + id, {
            name,
            description,
            active,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_audience = (id, campaign_id) => {
        return api.delete("/campaigns/" + campaign_id + "/audiences/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const IMPORT_audienceLeads = (campaign_id, audience_id, file) => {
        let formData = new FormData();
        formData.append("file", file);
        return api.post("campaigns/" + campaign_id + "/audiences/" + audience_id + "/leads/import", formData, {
            cancelToken: source.token,
            headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const IMPORT_audienceLeadsSample = () => {
        return api.get("/leads/import-example", {
            responseType: "arraybuffer",
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_audienceLeads = (campaign_id, audience_id, page_size, page, order_direction, search, filter_phone_country_id, filter_phone_area_id, filter_status, filter_assignee_ids, filter_contact_ids, filter_updated_at_start_date, filter_updated_at_end_date) => {
        return api.get("campaigns/" + campaign_id + "/audiences/" + audience_id + "/leads", {
            params: {
                page_size,
                page,
                order_direction,
                search,
                filter_phone_country_id,
                filter_phone_area_id,
                filter_status,
                filter_assignee_ids,
                filter_contact_ids,
                filter_updated_at_start_date,
                filter_updated_at_end_date,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_audienceLead = (campaign_id, audience_id, phone_area_id, number) => {
        return api.post("campaigns/" + campaign_id + "/audiences/" + audience_id + "/leads", {
            phone_area_id,
            number,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_audienceLead = (campaign_id, audience_id, id) => {
        return api.delete("/campaigns/" + campaign_id + "/audiences/" + audience_id + "/leads/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_availableLead = (campaign_id, invalid_lead_id) => {
        return api.post("campaigns/" + campaign_id + "/available-lead", {
            invalid_lead_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_audienceLead = (audience_id, campaign_id, lead_id, assignee_id) => {
        return api.post("/campaigns/" + campaign_id + "/audiences/" + audience_id + "/leads/" + lead_id, {
            assignee_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const EXPORT_audienceLeads = (campaign_id, audience_id, order_direction, search, filter_phone_country_id, filter_phone_area_id, filter_status, filter_assignee_ids, filter_contact_ids, filter_updated_at_start_date, filter_updated_at_end_date) => {
        return api.get("campaigns/" + campaign_id + "/audiences/" + audience_id + "/leads/export", {
            params: {
                order_direction,
                search,
                filter_phone_country_id,
                filter_phone_area_id,
                filter_status,
                filter_assignee_ids,
                filter_contact_ids,
                filter_updated_at_start_date,
                filter_updated_at_end_date,
            },
            responseType: "arraybuffer",
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Tickets
    const GET_tickets = (page_size, page, order_by, order_direction, search, search_by, filter_campaign_ids, filter_contact_ids, filter_status_ids, filter_priority_ids, filter_assignee_ids, filter_group_ids, filter_created_by_ids, filter_updated_by_ids, filter_draft, filter_unassigned, filter_ungrouped, filter_created_at_start_date, filter_created_at_end_date, filter_updated_at_start_date, filter_updated_at_end_date, filter_start_date_from, filter_start_date_to, filter_due_date_from, filter_due_date_to, include_count, count_only, filter_audience_ids, filter_custom_field_ids, filter_custom_field_values, filter_choice_ids, filter_choice_values, filter_contact_is_new, filter_inbound_channels, filter_outbound_channels, filter_inbound_social_channels, filter_outbound_social_channels, filter_forwarded_call, return_resource_type) => {
        return api.get("/tickets", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
                search_by,
                filter_campaign_ids,
                filter_contact_ids,
                filter_status_ids,
                filter_priority_ids,
                filter_assignee_ids,
                filter_group_ids,
                filter_created_by_ids,
                filter_updated_by_ids,
                filter_draft,
                filter_unassigned,
                filter_ungrouped,
                filter_created_at_start_date,
                filter_created_at_end_date,
                filter_updated_at_start_date,
                filter_updated_at_end_date,
                filter_start_date_from,
                filter_start_date_to,
                filter_due_date_from,
                filter_due_date_to,
                include_count,
                count_only,
                filter_audience_ids,
                filter_custom_field_ids,
                filter_custom_field_values,
                filter_choice_ids,
                filter_choice_values,
                filter_contact_is_new,
                filter_inbound_channels,
                filter_outbound_channels,
                filter_inbound_social_channels,
                filter_outbound_social_channels,
                filter_forwarded_call,
                return_resource_type,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_ticket = (campaign_id, contact_id, lead_id) => {
        return api.post("/tickets", {
            campaign_id,
            contact_id,
            lead_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_ticket = (ticket_id, lead_id) => {
        return api.post("/tickets/" + ticket_id, {
            lead_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CLONE_ticket = (ticket_id, lead_id) => {
        return api.get("/tickets/" + ticket_id + "/clone", {
            params: {
                lead_id,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_ticket = (id) => {
        return api.get("/tickets/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_ticket = (id) => {
        return api.delete("/tickets/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const EXPORT_tickets = (order_by, order_direction, search, search_by, filter_campaign_ids, filter_contact_ids, filter_status_ids, filter_priority_ids, filter_assignee_ids, filter_group_ids, filter_created_by_ids, filter_updated_by_ids, filter_draft, filter_unassigned, filter_ungrouped, filter_created_at_start_date, filter_created_at_end_date, filter_updated_at_start_date, filter_updated_at_end_date, filter_start_start_date, filter_start_end_date, filter_due_start_date, filter_due_end_date, filter_audience_ids, filter_custom_field_ids, filter_custom_field_values, filter_choice_ids, filter_choice_values, export_contact_fields_filter, export_contact_custom_fields_filter, export_ticket_fields_filter, export_ticket_choices_filter, export_ticket_custom_fields_filter, filter_contact_is_new, filter_inbound_channels, filter_outbound_channels, filter_inbound_social_channels, filter_outbound_social_channels, filter_forwarded_call) => {
        return api.get("/tickets/export", {
            params: {
                order_by,
                order_direction,
                search,
                search_by,
                filter_campaign_ids,
                filter_contact_ids,
                filter_status_ids,
                filter_priority_ids,
                filter_assignee_ids,
                filter_group_ids,
                filter_created_by_ids,
                filter_updated_by_ids,
                filter_draft,
                filter_unassigned,
                filter_ungrouped,
                filter_created_at_start_date,
                filter_created_at_end_date,
                filter_updated_at_start_date,
                filter_updated_at_end_date,
                filter_start_start_date,
                filter_start_end_date,
                filter_due_start_date,
                filter_due_end_date,
                filter_audience_ids,
                filter_custom_field_ids,
                filter_custom_field_values,
                filter_choice_ids,
                filter_choice_values,
                export_contact_fields_filter,
                export_contact_custom_fields_filter,
                export_ticket_fields_filter,
                export_ticket_choices_filter,
                export_ticket_custom_fields_filter,
                filter_contact_is_new,
                filter_inbound_channels,
                filter_outbound_channels,
                filter_inbound_social_channels,
                filter_outbound_social_channels,
                filter_forwarded_call,
            },
            responseType: "arraybuffer",
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_ticketInformation = (ticket_id) => {
        return api.get("/tickets/" + ticket_id + "/information", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_ticketInformation = (ticket_id, ticket_information, chosen_steps, groups, groups_campaign_step_id) => {
        return api.post("/tickets/" + ticket_id + "/information", {
            ticket_id,
            ticket_information,
            chosen_steps,
            groups,
            groups_campaign_step_id,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_ticketInformation = (ticket_id, ticket_information) => {
        return api.patch("/tickets/" + ticket_id + "/information", {
            ticket_information,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_informationHistory = (id, ticket_id) => {
        return api.delete("/tickets/" + ticket_id + "/ticket-history/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_ticketDetail = (ticket_id, ticket_detail_priority_id, ticket_detail_status_id, assignee_ids, estimate, start_date_time, due_date_time, progress, notes, forwarded_call, group_ids) => {
        return api.post("/tickets/" + ticket_id + "/detail", {
            ticket_id,
            estimate,
            progress,
            ticket_detail_priority_id,
            assignee_ids,
            ticket_detail_status_id,
            notes,
            start_date_time,
            due_date_time,
            forwarded_call,
            group_ids,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_ticketDetail = (id, ticket_id) => {
        return api.delete("/tickets/" + ticket_id + "/detail-history/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_ticketDetail = (id) => {
        return api.get("/tickets/" + id + "/detail", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_ticketActivity = (ticket_id, manually_entered, seconds_active, ticket_activity_type_id, incoming_channel, outgoing_channel, notes, recipient_ids, email_subject, email_content, custom_fields, recipient_is_contact) => {
        return api.post("/tickets/" + ticket_id + "/activity", {
            manually_entered,
            seconds_active,
            ticket_activity_type_id,
            incoming_channel,
            outgoing_channel,
            notes,
            recipient_ids,
            email_subject,
            email_content,
            custom_fields,
            recipient_is_contact,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_ticketActivity = (id, ticket_id, ticket_activity_type_id, manually_entered, seconds_active, incoming_channel, outgoing_channel, notes) => {
        return api.post("/tickets/" + ticket_id + "/activity/" + id, {
            ticket_activity_type_id,
            manually_entered,
            seconds_active,
            incoming_channel,
            outgoing_channel,
            notes,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_ticketActivity = (id, ticket_id) => {
        return api.delete("/tickets/" + ticket_id + "/activity/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_autofillData = (campaign_id, contact_id) => {
        return api.get("/campaigns/" + campaign_id + "/contact/" + contact_id + "/autofill-data", {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Blacklist
    const GET_blacklistedNumbers = (page_size, page, order_by, order_direction, search) => {
        return api.get("/blacklist-numbers", {
            params: {
                page_size,
                page,
                order_by,
                order_direction,
                search,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_blacklistedNumber = (phone_area_id, number) => {
        return api.post("/blacklist-numbers", {
            phone_area_id,
            number,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_blacklistedNumber = (id) => {
        return api.delete("/blacklist-numbers/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CHECK_blacklistedNumbers = (phone_area_id, number) => {
        return api.post("/blacklist-numbers/check", {
            phone_area_id,
            number,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const IMPORT_blacklistedNumbers = (file) => {
        let formData = new FormData();
        formData.append("file", file);
        return api.post("/blacklist-numbers/import", formData, {
            cancelToken: source.token,
            headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Metrics
    const GET_metrics = (type, table_name, interval, count_by, count_by_value, filter_by, filter_by_value, group_filter_include_children, invalidate_cache, interval_start_date, interval_end_date, interval_filter_by, ignore_null_values, chosen_statuses, chosen_priorities, count_by_function) => {
        let filterBy = filter_by;
        let filterByValue = filter_by_value;
        if (chosen_statuses) {
            filterBy = filterBy + ",ticketDetail.status";
            filterByValue = filterByValue + "," + chosen_statuses.join(";");
        }
        if (chosen_priorities) {
            filterBy = filterBy + ",ticketDetail.priority";
            filterByValue = filterByValue + "," + chosen_priorities.join(";");
        }
        return api.get("/metrics", {
            params: {
                type,
                table_name,
                interval,
                count_by,
                count_by_value,
                filter_by: filterBy,
                filter_by_value: filterByValue,
                group_filter_include_children,
                invalidate_cache,
                interval_start_date,
                interval_end_date,
                interval_filter_by,
                ignore_null_values,
                count_by_function,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    // Notifications
    const GET_notificationOptions = (page_size, page, order_direction, type) => {
        if (type === "user") {
            return api.get("/users/current/ticket-notification-options", {
                params: {
                    page_size,
                    page,
                    order_direction,
                },
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
        else {
            return api.get("/ticket-notification-options", {
                params: {
                    page_size,
                    page,
                    order_direction,
                },
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
    };
    const GET_notificationOption = (id, type) => {
        if (type === "tenant") {
            return api.get("/ticket-notification-options/" + id, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
        else {
            return api.get("/users/current/ticket-notification-options/" + id, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
    };
    const CREATE_notificationOptions = (on, via_email, via_web, campaign_ids, ticket_status_days, ticket_priority_days, notify_event, alert_advance_minutes, group_ids, ticket_detail_status_ids, ticket_detail_priority_ids, recipient_ids, assignee_ids, type, assigned_to_user, email_options, custom_email_subject, custom_email_content) => {
        if (type === "tenant") {
            return api.post("/ticket-notification-options", {
                on,
                via_email,
                via_web,
                campaign_ids,
                ticket_status_days,
                ticket_priority_days,
                notify_event,
                alert_advance_minutes,
                group_ids,
                ticket_detail_status_ids,
                ticket_detail_priority_ids,
                recipient_ids,
                assignee_ids,
                assigned_to_user,
                email_options,
                custom_email_subject,
                custom_email_content,
            }, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
        else {
            return api.post("/users/current/ticket-notification-options", {
                on,
                via_email,
                via_web,
                campaign_ids,
                ticket_status_days,
                ticket_priority_days,
                notify_event,
                alert_advance_minutes,
                group_ids,
                ticket_detail_status_ids,
                ticket_detail_priority_ids,
                recipient_ids,
                assignee_ids,
                assigned_to_user,
                email_options,
                custom_email_content,
            }, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
    };
    const UPDATE_notificationOptions = (id, on, via_email, via_web, campaign_ids, ticket_status_days, ticket_priority_days, notify_event, alert_advance_minutes, group_ids, ticket_detail_status_ids, ticket_detail_priority_ids, recipient_ids, assignee_ids, type, assigned_to_user, email_options, custom_email_subject, custom_email_content) => {
        if (type === "tenant") {
            return api.post("/ticket-notification-options/" + id, {
                on,
                via_email,
                via_web,
                campaign_ids,
                ticket_status_days,
                ticket_priority_days,
                notify_event,
                alert_advance_minutes,
                group_ids,
                ticket_detail_status_ids,
                ticket_detail_priority_ids,
                recipient_ids,
                assignee_ids,
                assigned_to_user,
                email_options,
                custom_email_subject,
                custom_email_content,
            }, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
        else {
            return api.post("/users/current/ticket-notification-options/" + id, {
                on,
                via_email,
                via_web,
                campaign_ids,
                ticket_status_days,
                ticket_priority_days,
                notify_event,
                alert_advance_minutes,
                group_ids,
                ticket_detail_status_ids,
                ticket_detail_priority_ids,
                recipient_ids,
                assignee_ids,
                assigned_to_user,
                email_options,
                custom_email_content,
            }, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
    };
    const DELETE_notificationOption = (id, type) => {
        if (type === "tenant") {
            return api.delete("/ticket-notification-options/" + id, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
        else {
            return api.delete("/users/current/ticket-notification-options/" + id, {
                cancelToken: source.token,
                headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                    Authorization: `Bearer ${prefs.tenantToken}`,
                })),
            });
        }
    };
    // Ticket Conditions
    const GET_ticketConditions = (page_size, page, order_direction) => {
        return api.get("/ticket-conditions", {
            params: {
                page_size,
                page,
                order_direction,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_ticketCondition = (id) => {
        return api.get("/ticket-conditions/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const CREATE_ticketCondition = (on, campaign_id, unchanged_for_minutes, unchanged_ticket_detail_priority_ids, unchanged_ticket_detail_status_ids, new_ticket_detail_status_id, new_ticket_detail_priority_id) => {
        return api.post("/ticket-conditions", {
            on,
            campaign_id,
            unchanged_ticket_detail_priority_ids,
            unchanged_ticket_detail_status_ids,
            new_ticket_detail_status_id,
            new_ticket_detail_priority_id,
            unchanged_for_minutes,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_ticketCondition = (id, on, campaign_id, unchanged_for_minutes, unchanged_ticket_detail_priority_ids, unchanged_ticket_detail_status_ids, new_ticket_detail_status_id, new_ticket_detail_priority_id) => {
        return api.post("/ticket-conditions/" + id, {
            on,
            campaign_id,
            unchanged_ticket_detail_priority_ids,
            unchanged_ticket_detail_status_ids,
            new_ticket_detail_status_id,
            new_ticket_detail_priority_id,
            unchanged_for_minutes,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const DELETE_ticketCondition = (id) => {
        return api.delete("/ticket-conditions/" + id, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_displayOptions = (search, type, campaign_id) => {
        return api.get(`/generate-display-options`, {
            params: {
                search,
                type,
                campaign_id,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const UPDATE_displayOptions = (displayOptions, role, campaign_id, type) => {
        return api.patch("/display-options", {
            column_display_options: displayOptions,
            role,
            campaign_id,
            type,
        }, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_displayOptionsForDashboard = () => {
        return api.get(`/display-options/dashboard`, {
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const GET_customMetrics = (id, interval_start_date, interval_end_date, invalidate_cache) => {
        return api.get(`/external-metric-definitions/${id}/metrics`, {
            params: {
                interval_start_date,
                interval_end_date,
                invalidate_cache,
            },
            cancelToken: source.token,
            headers: Object.assign({ "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
        });
    };
    const IMPORT_customMetrics = (id, file) => {
        let formData = new FormData();
        formData.append("file", file);
        return api.post(`/external-metric-definitions/${id}/import`, formData, {
            headers: Object.assign({ "Content-Type": "multipart/form-data", "Accept-Language": prefs.lang }, (prefs.tenantToken && {
                Authorization: `Bearer ${prefs.tenantToken}`,
            })),
            cancelToken: source.token,
        });
    };
    return {
        cancel,
        isCancel,
        // Users
        GET_users,
        CREATE_user,
        GET_user,
        UPDATE_user,
        DELETE_user,
        GET_currentUser,
        // Groups
        GET_groups,
        CREATE_group,
        GET_group,
        UPDATE_group,
        DELETE_group,
        // Users <> Groups
        GET_groupUsers,
        GET_groupAttachableUsers,
        CREATE_userOnGroup,
        DELETE_userFromGroup,
        // Phone Countries
        GET_phoneCountries,
        CREATE_phoneCountry,
        GET_phoneCountry,
        DELETE_phoneCountry,
        // Phone Areas
        GET_phoneAreas,
        CREATE_phoneArea,
        GET_phoneArea,
        UPDATE_phoneArea,
        DELETE_phoneArea,
        // Custom Fields
        GET_customFields,
        CREATE_customField,
        GET_customField,
        UPDATE_customField,
        DELETE_customField,
        REORDER_customFields,
        // Contacts
        GET_contacts,
        CREATE_contact,
        GET_contact,
        UPDATE_contact,
        DELETE_contact,
        EXPORT_contacts,
        IMPORT_contacts,
        IMPORT_contactsSample,
        DELETE_contactHistory,
        // Logs
        GET_logs,
        // Knowledge
        GET_knowledgeAll,
        CREATE_knowledge,
        GET_knowledge,
        UPDATE_knowledge,
        DELETE_knowledge,
        // Campaigns
        GET_campaigns,
        CREATE_campaign,
        GET_campaign,
        UPDATE_campaign,
        DELETE_campaign,
        GET_campaignFilterableFields,
        GET_campaignFilterableChoices,
        GET_campaignExportChoiceOptions,
        GET_campaignExportFieldOptions,
        GET_campaignCustomFieldMetrics,
        GET_campaignChoiceMetrics,
        // Campaign Steps
        GET_campaignSteps,
        CREATE_campaignStep,
        GET_campaignStep,
        UPDATE_campaignStep,
        DELETE_campaignStep,
        GET_campaignsChoiceFields,
        CREATE_campaignsChoiceField,
        GET_campaignsChoiceField,
        UPDATE_campaignsChoiceField,
        DELETE_campaignChoiceField,
        // Audiences
        GET_audiences,
        CREATE_audience,
        GET_audience,
        UPDATE_audience,
        DELETE_audience,
        IMPORT_audienceLeads,
        IMPORT_audienceLeadsSample,
        GET_audienceLeads,
        CREATE_audienceLead,
        DELETE_audienceLead,
        GET_availableLead,
        UPDATE_audienceLead,
        EXPORT_audienceLeads,
        // Tickets
        GET_tickets,
        CREATE_ticket,
        UPDATE_ticket,
        CLONE_ticket,
        GET_ticket,
        DELETE_ticket,
        EXPORT_tickets,
        GET_ticketInformation,
        CREATE_ticketInformation,
        UPDATE_ticketInformation,
        DELETE_informationHistory,
        GET_ticketDetail,
        CREATE_ticketDetail,
        DELETE_ticketDetail,
        CREATE_ticketActivity,
        UPDATE_ticketActivity,
        DELETE_ticketActivity,
        GET_autofillData,
        // Blacklist
        GET_blacklistedNumbers,
        CREATE_blacklistedNumber,
        DELETE_blacklistedNumber,
        CHECK_blacklistedNumbers,
        IMPORT_blacklistedNumbers,
        // Metrics
        GET_metrics,
        // Notifications
        GET_notificationOptions,
        GET_notificationOption,
        CREATE_notificationOptions,
        UPDATE_notificationOptions,
        DELETE_notificationOption,
        // Ticket Conditions
        GET_ticketConditions,
        GET_ticketCondition,
        CREATE_ticketCondition,
        UPDATE_ticketCondition,
        DELETE_ticketCondition,
        // Campaign columns
        GET_displayOptions,
        UPDATE_displayOptions,
        GET_displayOptionsForDashboard,
        // Custom metrics
        GET_customMetrics,
        IMPORT_customMetrics,
    };
};
