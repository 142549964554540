import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import InputSelect from "../inputs/input-select";
import InputRadio from "../inputs/input-radio";
import { flatGroups, groupInGroups, relatedChildGroups, relatedParentGroups, sortGroups, } from "../../../helpers/groups";
import { useApiTenant } from "../../../api/api-tenant";
import { useRoles } from "../../../services/roles";
export default function UserFilters({ showFilters, searchBy, setSearchBy, filterRole, setFilterRole, filterGroups, setFilterGroups, isLoading, setFilter, groups, setGroups, sortedGroups, setSortedGroups, relatedGroups, setRelatedGroups, areGroupsLoaded, setAreGroupsLoaded, }) {
    const { t } = useTranslation();
    const roles = useRoles();
    const api = useApiTenant();
    const [isGroupsLoading, setIsGroupsLoading] = useState(true);
    const [tab, setTab] = useState("filter_by");
    useEffect(() => {
        if (!showFilters)
            return;
        if (areGroupsLoaded)
            return;
        if (roles.isAgent) {
            if (roles.groupsTenant && roles.groupsTenant.length > 0) {
                let groups = flatGroups(roles.groupsTenant, 0, []);
                setGroups(groups);
                groups = groups.sort((a, b) => a.name.localeCompare(b.name));
                let sortedGroups = [];
                groups.map(group => {
                    sortedGroups.push({
                        id: "",
                        group: group,
                    });
                });
                setSortedGroups(sortedGroups);
            }
            setIsGroupsLoading(false);
        }
        else {
            api.GET_groups()
                .then(response => {
                let groups = flatGroups(response.data.groups, 0, []);
                setGroups(groups);
                groups = sortGroups(groups, 0, [], 1);
                setSortedGroups(groups);
                setIsGroupsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsGroupsLoading(false);
                }
            });
        }
        setAreGroupsLoaded(true);
        return () => api.cancel();
    }, [showFilters]);
    return (_jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showFilters }, { children: [_jsx(DialogTitle, { children: _jsxs(Tabs, Object.assign({ indicatorColor: "secondary", textColor: "inherit", value: tab, onChange: (event, value) => setTab(value), variant: "fullWidth" }, { children: [_jsx(Tab, { value: "filter_by", label: t("filters.filter-by") }), _jsx(Tab, { value: "search_by", label: t("filters.search-by") })] })) }), _jsx(DialogContent, { children: tab === "filter_by" ? (_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputSelect, { label: t("users.role"), required: false, options: [
                                    {
                                        label: t("users.roles.all"),
                                        value: "",
                                    },
                                    {
                                        label: t("users.roles.admin"),
                                        value: "admin",
                                    },
                                    {
                                        label: t("users.roles.manager"),
                                        value: "manager",
                                    },
                                    {
                                        label: t("users.roles.agent"),
                                        value: "agent",
                                    },
                                ], value: filterRole ? filterRole : "", setValue: setFilterRole }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { multiple: true, options: sortedGroups.map(sort => sort.group), getOptionLabel: group => {
                                    let sortedGroup = sortedGroups.find(sort => sort.group.id === group.id);
                                    return (sortedGroup === null || sortedGroup === void 0 ? void 0 : sortedGroup.id)
                                        ? (sortedGroup === null || sortedGroup === void 0 ? void 0 : sortedGroup.id) + ". " + group.name
                                        : group.name;
                                }, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => !!relatedGroups.find(group => group.id === option.id), loading: isGroupsLoading, value: isGroupsLoading ? [] : filterGroups, onChange: (event, value) => {
                                    let disabledGroups = [];
                                    value.map(group => {
                                        disabledGroups = relatedChildGroups(group, disabledGroups);
                                        disabledGroups = relatedParentGroups(group, groups, disabledGroups);
                                    });
                                    setRelatedGroups(disabledGroups);
                                    setFilterGroups(value);
                                }, onOpen: () => {
                                    let disabledGroups = [];
                                    filterGroups &&
                                        filterGroups.map(group => {
                                            let chosen = groupInGroups(group, groups);
                                            if (chosen) {
                                                disabledGroups = relatedChildGroups(chosen, disabledGroups);
                                                disabledGroups = relatedParentGroups(chosen, groups, disabledGroups);
                                            }
                                        });
                                    setRelatedGroups(disabledGroups);
                                }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("groups.groups"), fullWidth: true }))) }) }))] }))) : (_jsx(InputRadio, { required: false, type: "text", direction: "vertical", options: [
                        {
                            value: "",
                            label: t("users.user-filters.all"),
                        },
                        {
                            value: "full_name",
                            label: t("users.user-filters.full-name"),
                        },
                        {
                            value: "email",
                            label: t("users.user-filters.email"),
                        },
                        {
                            value: "username",
                            label: t("users.user-filters.username"),
                        },
                        {
                            value: "id",
                            label: "ID",
                        },
                    ], value: searchBy ? searchBy : "", setValue: setSearchBy })) }), _jsx(DialogActions, { children: _jsx(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", loading: isLoading, disabled: isLoading, onClick: () => {
                            setFilter(true);
                        }, loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("filters.apply") })) })) })] })));
}
