import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import { flatGroups, sortGroupsWithLevels, relatedChildGroups, relatedParentGroups, groupInGroups, flatGroupsWithLevels, } from "../../../helpers/groups";
import InputRadio from "../inputs/input-radio";
import InputCheckbox from "../inputs/input-checkbox";
import InputEditor from "../inputs/input-editor";
import { useAlert } from "../../../services/alert";
import { cleanText } from "../../../helpers/clean";
import { useRoles } from "../../../services/roles";
import { useApiTenant } from "../../../api/api-tenant";
import InputText from "../inputs/input-text";
export default function NotificationOptionForm({ status, option, setOption, isOpened, setIsOpened, setIsAdded, setIdToDelete, type, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const { t } = useTranslation();
    const alert = useAlert();
    const roles = useRoles();
    const api = useApiTenant();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [viaEmail, setViaEmail] = useState((_a = option === null || option === void 0 ? void 0 : option.via_email) !== null && _a !== void 0 ? _a : false);
    const [viaWeb, setViaWeb] = useState((_b = option === null || option === void 0 ? void 0 : option.via_web) !== null && _b !== void 0 ? _b : true);
    const [viaDisabled, setViaDisabled] = useState(false);
    const [ticketStatusDays] = useState((_c = option === null || option === void 0 ? void 0 : option.ticket_status_days) !== null && _c !== void 0 ? _c : null);
    const [ticketPriorityDays] = useState((_d = option === null || option === void 0 ? void 0 : option.ticket_priority_days) !== null && _d !== void 0 ? _d : null);
    const [notifyEvent, setNotifyEvent] = useState((_e = option === null || option === void 0 ? void 0 : option.notify_event) !== null && _e !== void 0 ? _e : "created");
    const [alertAdvanceMinutes, setAlertAdvanceMinutes] = useState((_f = option === null || option === void 0 ? void 0 : option.alert_advance_minutes) !== null && _f !== void 0 ? _f : 0);
    const alertAdvanceOptions = [0, 5, 10, 15, 30, 60, 120, 1440, 2880, 10080];
    const [chosenCampaigns, setChosenCampaigns] = useState((_g = option === null || option === void 0 ? void 0 : option.campaigns) !== null && _g !== void 0 ? _g : []);
    const [selectedGroups, setSelectedGroups] = useState((_h = option === null || option === void 0 ? void 0 : option.groups) !== null && _h !== void 0 ? _h : []);
    const [statuses, setStatuses] = useState((_j = option === null || option === void 0 ? void 0 : option.ticket_detail_statuses) !== null && _j !== void 0 ? _j : []);
    const [priorities, setPriorities] = useState((_k = option === null || option === void 0 ? void 0 : option.ticket_detail_priorities) !== null && _k !== void 0 ? _k : []);
    const [recipients, setRecipients] = useState((_l = option === null || option === void 0 ? void 0 : option.recipients) !== null && _l !== void 0 ? _l : []);
    const [assignedToMe, setAssignedToMe] = useState((_m = option === null || option === void 0 ? void 0 : option.assigned_to_user) !== null && _m !== void 0 ? _m : false);
    const [assignees] = useState((_o = option === null || option === void 0 ? void 0 : option.assignees) !== null && _o !== void 0 ? _o : []);
    const [campaigns, setCampaigns] = useState([]);
    const [campaignStatuses, setCampaignStatuses] = useState([]);
    const [campaignPriorities, setCampaignPriorities] = useState([]);
    const emailOptions = [
        {
            value: "toggle_message",
            label: t("notifications.notification-option-form.extended-email-options.toggle-message"),
        },
        {
            value: "contact",
            label: t("notifications.notification-option-form.extended-email-options.contact"),
        },
        {
            value: "information",
            label: t("notifications.notification-option-form.extended-email-options.information"),
        },
        {
            value: "details",
            label: t("notifications.notification-option-form.extended-email-options.details"),
        },
        {
            value: "button_sections",
            label: t("notifications.notification-option-form.extended-email-options.button-sections"),
        },
        {
            value: "custom_subject",
            label: t("notifications.notification-option-form.extended-email-options.custom-subject"),
        },
        {
            value: "custom_content",
            label: t("notifications.notification-option-form.extended-email-options.custom-content"),
        },
    ];
    const [checkedEmailOptions, setCheckedEmailOptions] = useState((option === null || option === void 0 ? void 0 : option.email_options) ? option === null || option === void 0 ? void 0 : option.email_options : ["toggle_message"]);
    const [customEmailSubject, setCustomEmailSubject] = useState((_p = option === null || option === void 0 ? void 0 : option.custom_email_subject) !== null && _p !== void 0 ? _p : "");
    const [customEmailContent, setCustomEmailContent] = useState((_q = option === null || option === void 0 ? void 0 : option.custom_email_content) !== null && _q !== void 0 ? _q : "");
    const [users, setUsers] = useState([]);
    const [isOptionOn, setIsOptionOn] = useState((_r = option === null || option === void 0 ? void 0 : option.on) !== null && _r !== void 0 ? _r : true);
    const [changeActive, setChangeActive] = useState(false);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [relatedGroups, setRelatedGroups] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isCampaignsLoading, setIsCampaignsLoading] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [searchUsers, setSearchUsers] = useState("");
    const [searchUsersBy, setSearchUsersBy] = useState("full_name");
    const [showUserFilters, setShowUserFilters] = useState(false);
    useEffect(() => {
        if (campaigns.length > 0)
            return;
        setIsCampaignsLoading(true);
        api.GET_campaigns(10000, 1, undefined, undefined, "name", undefined, 1, roles.isAgent ? 1 : undefined)
            .then(response => {
            const campaign = response.data.campaigns.filter(campaign => campaign.active === true);
            campaign.unshift({
                name: t("notifications.notification-option-form.any-campaign"),
                id: "-1",
            });
            if (chosenCampaigns.length === 1 && chosenCampaigns[0].id !== "-1") {
                const campaignId = chosenCampaigns[0].id;
                const chosenCampaign = campaign.find(campaign => campaign.id === campaignId);
                if (chosenCampaign && chosenCampaign.groups && chosenCampaign.details_groups) {
                    const chosenCampaignGroups = [
                        ...chosenCampaign.groups,
                        ...chosenCampaign.details_groups,
                    ];
                    let campaignGroups = flatGroups(chosenCampaignGroups, 0, []);
                    setGroups(campaignGroups);
                    let groupsLevels = flatGroupsWithLevels(chosenCampaignGroups, 0, []);
                    campaignGroups = sortGroupsWithLevels(groupsLevels, 0, [], 1);
                    setSortedGroups(campaignGroups);
                }
            }
            setCampaigns(campaign);
            setIsCampaignsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsCampaignsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (campaigns &&
            chosenCampaigns &&
            chosenCampaigns.length === 1 &&
            chosenCampaigns[0].id !== "-1") {
            let campaign = campaigns.find(a => a.id === chosenCampaigns[0].id);
            if (campaign === null || campaign === void 0 ? void 0 : campaign.groups) {
                let campaignGroups = flatGroups(campaign === null || campaign === void 0 ? void 0 : campaign.groups, 0, []);
                setGroups(campaignGroups);
                let groupsLevels = flatGroupsWithLevels(campaign === null || campaign === void 0 ? void 0 : campaign.groups, 0, []);
                campaignGroups = sortGroupsWithLevels(groupsLevels, 0, [], 1);
                campaignGroups.unshift({
                    id: "-3",
                    group: {
                        name: t("notifications.notification-option-form.any-or-none"),
                        id: "-3",
                    },
                }, {
                    id: "-1",
                    group: {
                        name: t("notifications.notification-option-form.any-group"),
                        id: "-1",
                    },
                }, {
                    id: "-2",
                    group: {
                        name: t("notifications.notification-option-form.none-group"),
                        id: "-2",
                    },
                });
                setSortedGroups(campaignGroups);
                if (status === "new" || (status === "edit" && selectedGroups.length === 0))
                    setSelectedGroups([
                        {
                            name: t("notifications.notification-option-form.any-or-none"),
                            id: "-3",
                        },
                    ]);
            }
            if (campaign) {
                let prios;
                prios = campaign.ticket_priorities;
                if (!prios.find(a => a.id === "-1"))
                    prios.unshift({
                        name: t("notifications.notification-option-form.any-priority"),
                        id: "-1",
                    });
                let statuses1;
                statuses1 = campaign.ticket_statuses;
                if (!statuses1.find(a => a.id === "-1"))
                    statuses1.unshift({
                        name: t("notifications.notification-option-form.any-status"),
                        id: "-1",
                    });
                setCampaignPriorities(prios);
                setCampaignStatuses(statuses1);
                if (status === "new" || (status === "edit" && priorities.length === 0)) {
                    setPriorities([
                        {
                            name: t("notifications.notification-option-form.any-priority"),
                            id: "-1",
                        },
                    ]);
                }
                if (status === "new" || (status === "edit" && statuses.length === 0)) {
                    setStatuses([
                        {
                            name: t("notifications.notification-option-form.any-status"),
                            id: "-1",
                        },
                    ]);
                }
            }
        }
        else {
            setGroups([]);
            setSortedGroups([]);
            setStatuses([
                {
                    name: t("notifications.notification-option-form.any-status"),
                    id: "-1",
                },
            ]);
            setPriorities([
                {
                    name: t("notifications.notification-option-form.any-priority"),
                    id: "-1",
                },
            ]);
            setSelectedGroups([
                {
                    name: t("notifications.notification-option-form.any-or-none"),
                    id: "-3",
                },
            ]);
            setCampaignPriorities([]);
            setCampaignStatuses([]);
        }
        if (chosenCampaigns.length === 0) {
            setChosenCampaigns(new Array({
                name: t("notifications.notification-option-form.any-campaign"),
                id: "-1",
            }));
        }
    }, [chosenCampaigns, campaigns.length]);
    useEffect(() => {
        if (users.length === 0 &&
            !recipients.find(a => a.id !== "-1" && a.id !== "-2" && a.id !== "-3" && a.id !== "-4")) {
            let defaultUsers = [
                {
                    id: "-1",
                    first_name: t("notifications.notification-option-form.updated-by"),
                },
                {
                    id: "-2",
                    first_name: t("notifications.notification-option-form.created-by"),
                },
                {
                    id: "-3",
                    first_name: t("notifications.notification-option-form.assigned-to"),
                },
                {
                    id: "-4",
                    first_name: t("notifications.notification-option-form.contact"),
                },
            ];
            setUsers(defaultUsers);
        }
        if (recipients.length === 0) {
            if (option && option.recipients_condition_type !== null) {
                if (option.recipients_condition_type === "assignees") {
                    setRecipients([
                        {
                            id: "-3",
                            first_name: t("notifications.notification-option-form.assigned-to"),
                        },
                    ]);
                }
                else if (option.recipients_condition_type === "updated_by") {
                    setRecipients([
                        {
                            id: "-1",
                            first_name: t("notifications.notification-option-form.updated-by"),
                        },
                    ]);
                }
                else if (option.recipients_condition_type === "contact") {
                    setRecipients([
                        {
                            id: "-4",
                            first_name: t("notifications.notification-option-form.contact"),
                        },
                    ]);
                }
                else {
                    setRecipients([
                        {
                            id: "-2",
                            first_name: t("notifications.notification-option-form.created-by"),
                        },
                    ]);
                }
            }
            else {
                if (notifyEvent === "created" || notifyEvent === "created_or_updated") {
                    setRecipients([
                        {
                            id: "-3",
                            first_name: t("notifications.notification-option-form.assigned-to"),
                        },
                    ]);
                }
                else if (notifyEvent === "updated") {
                    setRecipients([
                        {
                            id: "-2",
                            first_name: t("notifications.notification-option-form.created-by"),
                        },
                    ]);
                }
                else {
                    setRecipients([
                        {
                            id: "-1",
                            first_name: t("notifications.notification-option-form.updated-by"),
                        },
                    ]);
                }
            }
        }
        const isContact = recipients.find(a => a.id === "-4");
        if (isContact) {
            setViaEmail(true);
            setViaWeb(false);
            setViaDisabled(true);
        }
        else {
            setViaDisabled(false);
        }
    }, [users, recipients]);
    useEffect(() => {
        if (recipients.length === 1 &&
            (recipients[0].id === "-1" || recipients[0].id === "-2" || recipients[0].id === "-3")) {
            if (notifyEvent === "created" ||
                notifyEvent === "updated" ||
                notifyEvent === "created_or_updated") {
                setAlertAdvanceMinutes(0);
                if (notifyEvent === "updated" && recipients[0].id !== "-2") {
                    setRecipients([
                        {
                            id: "-2",
                            first_name: t("notifications.notification-option-form.created-by"),
                        },
                    ]);
                }
                if ((notifyEvent === "created" || notifyEvent === "created_or_updated") &&
                    recipients[0].id !== "-3") {
                    setRecipients([
                        {
                            id: "-3",
                            first_name: t("notifications.notification-option-form.assigned-to"),
                        },
                    ]);
                }
            }
            else {
                setRecipients([
                    {
                        id: "-1",
                        first_name: t("notifications.notification-option-form.updated-by"),
                    },
                ]);
            }
        }
    }, [notifyEvent]);
    useEffect(() => {
        if (changeActive && option) {
            updateNotificationOption();
            setChangeActive(false);
        }
    }, [changeActive]);
    useEffect(() => {
        if (!viaEmail && !viaWeb)
            setViaWeb(true);
        if (!viaEmail) {
            setCheckedEmailOptions(["toggle_message"]);
            setCustomEmailSubject("");
            setCustomEmailContent("");
        }
    }, [viaEmail, viaWeb]);
    useEffect(() => {
        if (isOpened && status === "new") {
            setGroups([]);
            setStatuses([]);
            setPriorities([]);
            setRecipients([]);
            setAlertAdvanceMinutes(0);
            setViaEmail(false);
            setViaDisabled(false);
            setViaWeb(true);
            setNotifyEvent("created");
            setChosenCampaigns([]);
            setSelectedGroups([]);
            setIsSaving(false);
        }
    }, [isOpened]);
    useEffect(() => {
        if (checkedEmailOptions.length === 0) {
            setCheckedEmailOptions(["toggle_message"]);
        }
    }, [checkedEmailOptions.length]);
    const createNotificationOption = () => {
        setIsSaving(true);
        api.CREATE_notificationOptions(isOptionOn, viaEmail, viaWeb, chosenCampaigns.find(a => a.id === "-1") ? ["any"] : chosenCampaigns.map(a => a.id), ticketStatusDays, ticketPriorityDays, notifyEvent, alertAdvanceMinutes, selectedGroups.find(a => a.id === "-1")
            ? [""]
            : selectedGroups.find(a => a.id === "-2")
                ? ["none"]
                : selectedGroups.find(a => a.id === "-3")
                    ? ["any_or_none"]
                    : selectedGroups.map(g => g.id), statuses.find(a => a.id === "-1") ? ["any"] : statuses.map(a => a.id), priorities.find(a => a.id === "-1") ? ["any"] : priorities.map(a => a.id), recipients.find(a => a.id === "-1")
            ? ["updated_by"]
            : recipients.find(a => a.id === "-2")
                ? ["created_by"]
                : recipients.find(a => a.id === "-3")
                    ? ["assignees"]
                    : recipients.find(a => a.id === "-4")
                        ? ["contact"]
                        : recipients.map(g => g.id), assignees.map(a => a.id), type, assignedToMe, viaEmail && checkedEmailOptions.length > 0 ? checkedEmailOptions : undefined, customEmailSubject !== "" ? customEmailSubject : undefined, customEmailContent !== "" ? customEmailContent : undefined)
            .then(() => {
            if (setIsAdded)
                setIsAdded(true);
            if (setIsOpened)
                setIsOpened(false);
            if (setOption)
                setOption(null);
            alert.show(t("notifications.notifications-table.successfully-added"), "success");
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                if (setIsAdded)
                    setIsAdded(true);
                if (setIsOpened)
                    setIsOpened(false);
                console.error(error);
                alert.show(t("notifications.notifications-table.creating-error-occured"), "error");
            }
        });
    };
    const updateNotificationOption = () => {
        setIsSaving(true);
        api.UPDATE_notificationOptions(option.id, isOptionOn, viaEmail, viaWeb, chosenCampaigns.find(a => a.id === "-1") ? ["any"] : chosenCampaigns.map(a => a.id), ticketStatusDays, ticketPriorityDays, notifyEvent, alertAdvanceMinutes, selectedGroups.find(a => a.id === "-1")
            ? ["any"]
            : selectedGroups.find(a => a.id === "-2")
                ? ["none"]
                : selectedGroups.find(a => a.id === "-3")
                    ? ["any_or_none"]
                    : selectedGroups.map(g => g.id), statuses.find(a => a.id === "-1") ? ["any"] : statuses.map(a => a.id), priorities.find(a => a.id === "-1") ? ["any"] : priorities.map(a => a.id), recipients.find(a => a.id === "-1")
            ? ["updated_by"]
            : recipients.find(a => a.id === "-2")
                ? ["created_by"]
                : recipients.find(a => a.id === "-3")
                    ? ["assignees"]
                    : recipients.find(a => a.id === "-4")
                        ? ["contact"]
                        : recipients.map(g => g.id), assignees.map(a => a.id), type, assignedToMe, viaEmail && checkedEmailOptions.length > 0 ? checkedEmailOptions : undefined, customEmailSubject !== "" ? customEmailSubject : undefined, customEmailContent !== "" ? customEmailContent : undefined)
            .then(() => {
            if (setIsAdded)
                setIsAdded(true);
            if (setIsOpened)
                setIsOpened(false);
            if (setOption)
                setOption(null);
            alert.show(t("notifications.notifications-table.successfully-updated"), "success");
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                if (setIsAdded)
                    setIsAdded(true);
                if (setIsOpened)
                    setIsOpened(false);
                console.error(error);
                alert.show(t("notifications.notifications-table.updating-error-occured"), "error");
            }
        });
    };
    useEffect(() => {
        setUsers([]);
        if (searchUsers === "") {
            return;
        }
        setIsUsersLoading(true);
        debounceSearchUsers(cleanText(searchUsers), recipients, searchUsersBy);
    }, [searchUsers]);
    const debounceSearchUsers = useCallback(debounce((search, recipients, searchUsersBy) => {
        getUsers(search, recipients, searchUsersBy);
    }, 700), []);
    const getUsers = (search, recipients, searchUsersBy) => {
        api.GET_users(25, 1, "id", "asc", search, searchUsersBy)
            .then(response => {
            let users = response.data.tenant_users;
            if (recipients.length > 0) {
                users.push(...recipients);
                users = [...new Set(users)];
            }
            setUsers(users);
            setIsUsersLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsUsersLoading(false);
            }
        });
    };
    return (_jsxs(Fragment, { children: [_jsxs(Dialog, Object.assign({ disableEnforceFocus: true, open: isOpened, onClose: () => {
                    setIsOpened(false);
                    if (setOption)
                        setOption(null);
                } }, { children: [_jsx(DialogTitle, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6, md: 8 }, { children: _jsx(Typography, Object.assign({ sx: { paddingTop: 0.5 }, variant: "h6" }, { children: t("notifications.notifications-table.send-notification") })) })), status === "edit" && (_jsx(Grid, Object.assign({ item: true, xs: 6, md: 4 }, { children: _jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            paddingTop: isMobile ? 2 : 0,
                                        } }, { children: _jsx(FormControlLabel, { sx: {
                                                marginRight: 0,
                                            }, control: _jsx(Switch, { color: "secondary", checked: isOptionOn, onChange: () => {
                                                    setIsOptionOn(!isOptionOn);
                                                    setChangeActive(true);
                                                } }), 
                                            // @ts-ignore
                                            label: t("notifications.notifications-table.active") }) })) })))] })) }), _jsxs(DialogContent, { children: [status === "edit" && !(option === null || option === void 0 ? void 0 : option.on) && (_jsx(Box, Object.assign({ sx: { paddingBottom: 4 } }, { children: _jsx(Alert, Object.assign({ severity: "warning" }, { children: t("notifications.notification-option-form.notification-disabled") })) }))), _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("notifications.notification-option-form.for") +
                                                        " " +
                                                        t("notifications.notifications-table.event").toLocaleLowerCase() +
                                                        " *" }), _jsxs(Select, Object.assign({ label: t("notifications.notification-option-form.for") +
                                                        " " +
                                                        t("notifications.notifications-table.event").toLocaleLowerCase() +
                                                        " *", value: notifyEvent, onChange: event => setNotifyEvent(event.target.value) }, { children: [_jsx(MenuItem, Object.assign({ value: "created" }, { children: t("notifications.notification-option-form.new") }), "created"), _jsx(MenuItem, Object.assign({ value: "updated" }, { children: t("notifications.notification-option-form.existing") }), "updated"), _jsx(MenuItem, Object.assign({ value: "created_or_updated" }, { children: t("notifications.notification-option-form.new-existing") }), "created_or_updated"), _jsx(MenuItem, Object.assign({ value: "scheduled" }, { children: t("notifications.notification-option-form.scheduled-ticket") }), "scheduled"), _jsx(MenuItem, Object.assign({ value: "due" }, { children: t("notifications.notification-option-form.due-date-ticket") }), "due"), _jsx(MenuItem, Object.assign({ value: "public_activity_created" }, { children: t("notifications.notification-option-form.new-public-activity") }), "public_activity_created")] }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { options: campaigns, getOptionLabel: campaign => campaign.name, isOptionEqualToValue: (option, value) => option.id === value.id, multiple: true, value: chosenCampaigns, onChange: (event, value) => {
                                                if (chosenCampaigns.find(a => a.id === "-1") &&
                                                    value.find(b => b.id !== "-1")) {
                                                    let val = value.filter(c => c.id !== "-1");
                                                    setChosenCampaigns(val);
                                                }
                                                else if (!chosenCampaigns.find(a => a.id === "-1") &&
                                                    value.find(b => b.id === "-1")) {
                                                    let val = value.filter(c => c.id === "-1");
                                                    setChosenCampaigns(val);
                                                }
                                                else {
                                                    setChosenCampaigns(value);
                                                }
                                            }, loading: isCampaignsLoading, renderOption: (props, campaign) => (_jsx("li", Object.assign({}, props, { children: _jsx(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    } }, { children: _jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: campaign.name })) })) }), campaign.id) }))), renderTags: value => value.map(option => (_jsx(Chip, { variant: "filled", label: option.name, deleteIcon: chosenCampaigns.find(a => a.id === "-1") &&
                                                    undefined, onDelete: chosenCampaigns.find(a => a.id !== "-1")
                                                    ? () => {
                                                        if (chosenCampaigns.find(a => a.id !== "-1")) {
                                                            let newCampaigns = chosenCampaigns.filter(a => a.id !== option.id);
                                                            if (newCampaigns.length === 0) {
                                                                newCampaigns.push({
                                                                    name: t("notifications.notification-option-form.any-campaign"),
                                                                    id: "-1",
                                                                });
                                                            }
                                                            setChosenCampaigns(newCampaigns);
                                                        }
                                                    }
                                                    : undefined }, option.id))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("notifications.notification-option-form.in") +
                                                    " " +
                                                    t("notifications.notification-option-form.campaigns") +
                                                    " *", fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { disabled: chosenCampaigns.length === 0 ||
                                                    chosenCampaigns.length > 1 ||
                                                    (chosenCampaigns.length === 1 &&
                                                        chosenCampaigns[0].id === "-1"), multiple: true, options: sortedGroups.map(sort => sort.group), getOptionLabel: group => {
                                                    var _a, _b, _c, _d;
                                                    return sortedGroups.find(sort => sort.group.id === group.id)
                                                        ? ((_a = sortedGroups.find(sort => sort.group.id === group.id)) === null || _a === void 0 ? void 0 : _a.id) !== "-1" &&
                                                            ((_b = sortedGroups.find(sort => sort.group.id === group.id)) === null || _b === void 0 ? void 0 : _b.id) !== "-2" &&
                                                            ((_c = sortedGroups.find(sort => sort.group.id === group.id)) === null || _c === void 0 ? void 0 : _c.id) !== "-3"
                                                            ? ((_d = sortedGroups.find(sort => sort.group.id === group.id)) === null || _d === void 0 ? void 0 : _d.id) +
                                                                ". " +
                                                                group.name
                                                            : group.name
                                                        : group.name;
                                                }, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => !!relatedGroups.find(group => group.id === option.id), value: selectedGroups, disableCloseOnSelect: true, onChange: (event, value) => {
                                                    if (selectedGroups.find(a => a.id === "-1") &&
                                                        value.find(b => b.id !== "-1")) {
                                                        let val = value.filter(c => c.id !== "-1");
                                                        setSelectedGroups(val);
                                                    }
                                                    else if (!selectedGroups.find(a => a.id === "-1") &&
                                                        value.find(b => b.id === "-1")) {
                                                        let val = value.filter(c => c.id === "-1");
                                                        setSelectedGroups(val);
                                                    }
                                                    else if (selectedGroups.find(a => a.id === "-2") &&
                                                        value.find(b => b.id !== "-2")) {
                                                        let val = value.filter(c => c.id !== "-2");
                                                        setSelectedGroups(val);
                                                    }
                                                    else if (!selectedGroups.find(a => a.id === "-2") &&
                                                        value.find(b => b.id === "-2")) {
                                                        let val = value.filter(c => c.id === "-2");
                                                        setSelectedGroups(val);
                                                    }
                                                    else if (selectedGroups.find(a => a.id === "-3") &&
                                                        value.find(b => b.id !== "-3")) {
                                                        let val = value.filter(c => c.id !== "-3");
                                                        setSelectedGroups(val);
                                                    }
                                                    else if (!selectedGroups.find(a => a.id === "-3") &&
                                                        value.find(b => b.id === "-3")) {
                                                        let val = value.filter(c => c.id === "-3");
                                                        setSelectedGroups(val);
                                                    }
                                                    else {
                                                        let disabledGroups = [];
                                                        value.map(group => {
                                                            disabledGroups = relatedChildGroups(group, disabledGroups);
                                                            disabledGroups = relatedParentGroups(group, groups, disabledGroups);
                                                        });
                                                        setRelatedGroups(disabledGroups);
                                                        setSelectedGroups(value);
                                                    }
                                                }, onOpen: () => {
                                                    let disabledGroups = [];
                                                    selectedGroups &&
                                                        selectedGroups.map(group => {
                                                            let chosen = groupInGroups(group, groups);
                                                            if (chosen) {
                                                                disabledGroups = relatedChildGroups(chosen, disabledGroups);
                                                                disabledGroups = relatedParentGroups(chosen, groups, disabledGroups);
                                                            }
                                                        });
                                                    setRelatedGroups(disabledGroups);
                                                }, renderTags: value => value.map(option => {
                                                    var _a, _b, _c, _d;
                                                    return (_jsx(Chip, { variant: "filled", label: sortedGroups.find(sort => sort.group.id === option.id)
                                                            ? ((_a = sortedGroups.find(sort => sort.group.id === option.id)) === null || _a === void 0 ? void 0 : _a.id) !== "-1" &&
                                                                ((_b = sortedGroups.find(sort => sort.group.id === option.id)) === null || _b === void 0 ? void 0 : _b.id) !== "-2" &&
                                                                ((_c = sortedGroups.find(sort => sort.group.id === option.id)) === null || _c === void 0 ? void 0 : _c.id) !== "-3"
                                                                ? ((_d = sortedGroups.find(sort => sort.group.id === option.id)) === null || _d === void 0 ? void 0 : _d.id) +
                                                                    ". " +
                                                                    option.name
                                                                : option.name
                                                            : option.name, deleteIcon: (selectedGroups.find(a => a.id === "-1") ||
                                                            selectedGroups.find(a => a.id === "-2") ||
                                                            selectedGroups.find(a => a.id === "-3")) &&
                                                            undefined, onDelete: selectedGroups.find(a => a.id !== "-1") &&
                                                            selectedGroups.find(a => a.id !== "-2") &&
                                                            selectedGroups.find(a => a.id !== "-3")
                                                            ? () => {
                                                                if (selectedGroups.find(a => a.id !== "-1") ||
                                                                    selectedGroups.find(a => a.id !== "-2") ||
                                                                    selectedGroups.find(a => a.id !== "-3")) {
                                                                    let newSelectedGroups = selectedGroups.filter(a => a.id !== option.id);
                                                                    if (newSelectedGroups.length === 0) {
                                                                        newSelectedGroups.push({
                                                                            name: t("notifications.notification-option-form.any-or-none"),
                                                                            id: "-3",
                                                                        });
                                                                    }
                                                                    setSelectedGroups(newSelectedGroups);
                                                                }
                                                            }
                                                            : undefined }, option.id));
                                                }), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("notifications.notification-option-form.with") +
                                                        " " +
                                                        t("notifications.notification-option-form.groups").toLocaleLowerCase(), InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { multiple: true, disabled: chosenCampaigns.length === 0 ||
                                                (chosenCampaigns.length === 1 &&
                                                    chosenCampaigns[0].id === "-1") ||
                                                chosenCampaigns.length > 1, disableClearable: !!statuses.find(a => a.id === "-1"), options: campaignStatuses, getOptionLabel: status => status.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: statuses, onChange: (event, value) => {
                                                if (statuses.find(a => a.id === "-1") &&
                                                    value.find(b => b.id !== "-1")) {
                                                    let val = value.filter(c => c.id !== "-1");
                                                    setStatuses(val);
                                                }
                                                else if (!statuses.find(a => a.id === "-1") &&
                                                    value.find(b => b.id === "-1")) {
                                                    let val = value.filter(c => c.id === "-1");
                                                    setStatuses(val);
                                                }
                                                else {
                                                    setStatuses(value);
                                                }
                                            }, renderTags: value => value.map(option => (_jsx(Chip, { variant: "filled", label: option.name, deleteIcon: statuses.find(a => a.id === "-1") && undefined, onDelete: statuses.find(a => a.id !== "-1")
                                                    ? () => {
                                                        if (statuses.find(a => a.id !== "-1")) {
                                                            let newStatuses = statuses.filter(a => a.id !== option.id);
                                                            if (newStatuses.length === 0) {
                                                                newStatuses.push({
                                                                    name: t("notifications.notification-option-form.any-status"),
                                                                    id: "-1",
                                                                });
                                                            }
                                                            setStatuses(newStatuses);
                                                        }
                                                    }
                                                    : undefined }, option.id))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("notifications.notification-option-form.and") +
                                                    " " +
                                                    t("notifications.notification-option-form.statuses").toLocaleLowerCase(), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(Autocomplete, { multiple: true, disabled: chosenCampaigns.length === 0 ||
                                                (chosenCampaigns.length === 1 &&
                                                    chosenCampaigns[0].id === "-1") ||
                                                chosenCampaigns.length > 1, options: campaignPriorities, getOptionLabel: priority => priority.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: priorities, onChange: (event, value) => {
                                                if (priorities.find(a => a.id === "-1") &&
                                                    value.find(b => b.id !== "-1")) {
                                                    let val = value.filter(c => c.id !== "-1");
                                                    setPriorities(val);
                                                }
                                                else if (!priorities.find(a => a.id === "-1") &&
                                                    value.find(b => b.id === "-1")) {
                                                    let val = value.filter(c => c.id === "-1");
                                                    setPriorities(val);
                                                }
                                                else {
                                                    setPriorities(value);
                                                }
                                            }, renderTags: value => value.map(option => (_jsx(Chip, { variant: "filled", label: option.name, deleteIcon: priorities.find(a => a.id === "-1") && undefined, onDelete: priorities.find(a => a.id !== "-1")
                                                    ? () => {
                                                        if (priorities.find(a => a.id !== "-1")) {
                                                            let newPriorities = priorities.filter(a => a.id !== option.id);
                                                            if (newPriorities.length === 0) {
                                                                newPriorities.push({
                                                                    name: t("notifications.notification-option-form.any-priority"),
                                                                    id: "-1",
                                                                });
                                                            }
                                                            setPriorities(newPriorities);
                                                        }
                                                    }
                                                    : undefined }, option.id))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("notifications.notification-option-form.and") +
                                                    " " +
                                                    t("notifications.notification-option-form.priorities").toLocaleLowerCase(), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isCampaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), type === "tenant" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: _jsx(Autocomplete, { getOptionDisabled: option => !!users.find(user => user.id === option.id &&
                                                option.id === "-1" &&
                                                notifyEvent === "updated") ||
                                                !!users.find(user => user.id === option.id &&
                                                    option.id === "-2" &&
                                                    notifyEvent === "created") ||
                                                !!users.find(user => user.id === option.id &&
                                                    option.id === "-1" &&
                                                    notifyEvent === "created"), options: users, getOptionLabel: user => (user.first_name ? user.first_name + " " : "") +
                                                (user.last_name ? user.last_name + " " : "") +
                                                (user.email ? "(" + user.email + ") " : "") +
                                                (user.username ? "(" + user.username + ") " : ""), multiple: true, isOptionEqualToValue: (option, value) => option.id == value.id, value: recipients, onChange: (event, value) => {
                                                if (recipients.find(a => a.id === "-1") &&
                                                    value.find(b => b.id !== "-1")) {
                                                    let val = value.filter(c => c.id !== "-1");
                                                    setRecipients(val);
                                                }
                                                else if (!recipients.find(a => a.id === "-1") &&
                                                    value.find(b => b.id === "-1")) {
                                                    let val = value.filter(c => c.id === "-1");
                                                    setRecipients(val);
                                                }
                                                else if (recipients.find(a => a.id === "-2") &&
                                                    value.find(b => b.id !== "-2")) {
                                                    let val = value.filter(c => c.id !== "-2");
                                                    setRecipients(val);
                                                }
                                                else if (!recipients.find(a => a.id === "-2") &&
                                                    value.find(b => b.id === "-2")) {
                                                    let val = value.filter(c => c.id === "-2");
                                                    setRecipients(val);
                                                }
                                                else if (recipients.find(a => a.id === "-3") &&
                                                    value.find(b => b.id !== "-3")) {
                                                    let val = value.filter(c => c.id !== "-3");
                                                    setRecipients(val);
                                                }
                                                else if (!recipients.find(a => a.id === "-3") &&
                                                    value.find(b => b.id === "-3")) {
                                                    let val = value.filter(c => c.id === "-3");
                                                    setRecipients(val);
                                                }
                                                else if (recipients.find(a => a.id === "-4") &&
                                                    value.find(b => b.id !== "-4")) {
                                                    let val = value.filter(c => c.id !== "-4");
                                                    setRecipients(val);
                                                }
                                                else if (!recipients.find(a => a.id === "-4") &&
                                                    value.find(b => b.id === "-4")) {
                                                    let val = value.filter(c => c.id === "-4");
                                                    setRecipients(val);
                                                }
                                                else {
                                                    setSearchUsers("");
                                                    setRecipients(value);
                                                }
                                            }, loading: isUsersLoading, filterSelectedOptions: true, filterOptions: options => options, noOptionsText: t("filters.search-users-by") +
                                                " (" +
                                                (searchUsersBy === "full_name"
                                                    ? t("users.user-attributes.full-name").toLocaleLowerCase()
                                                    : searchUsersBy === "email"
                                                        ? t("users.user-attributes.email").toLocaleLowerCase()
                                                        : searchUsersBy === "username"
                                                            ? t("users.user-attributes.username").toLocaleLowerCase()
                                                            : "ID") +
                                                ")", onInputChange: (event, value) => setSearchUsers(value), renderOption: (props, user) => (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                display: "flex",
                                                            } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: (user.first_name
                                                                    ? user.first_name + " "
                                                                    : "") +
                                                                    (user.last_name
                                                                        ? user.last_name + " "
                                                                        : "") })) })), user.email && (_jsx(Box, Object.assign({ sx: {
                                                                display: "flex",
                                                            } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: user.email })) }))), user.username && (_jsx(Box, Object.assign({ sx: {
                                                                display: "flex",
                                                            } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: user.username })) })))] }), user.id) }))), renderTags: value => value.map(option => {
                                                var _a, _b, _c, _d;
                                                return (_jsx(Chip, { variant: "filled", label: ((_a = users.find(user => user.id === option.id)) === null || _a === void 0 ? void 0 : _a.id) !== "-1" &&
                                                        ((_b = users.find(user => user.id === option.id)) === null || _b === void 0 ? void 0 : _b.id) !== "-2" &&
                                                        ((_c = users.find(user => user.id === option.id)) === null || _c === void 0 ? void 0 : _c.id) !== "-3" &&
                                                        ((_d = users.find(user => user.id === option.id)) === null || _d === void 0 ? void 0 : _d.id) !== "-4"
                                                        ? (option.first_name
                                                            ? option.first_name + " "
                                                            : "") +
                                                            (option.last_name
                                                                ? option.last_name + " "
                                                                : "") +
                                                            (option.email
                                                                ? "(" + option.email + ")"
                                                                : "")
                                                        : option.first_name, deleteIcon: (recipients.find(a => a.id === "-1") ||
                                                        recipients.find(a => a.id === "-2") ||
                                                        recipients.find(a => a.id === "-3") ||
                                                        recipients.find(a => a.id === "-4")) &&
                                                        undefined, onDelete: recipients.find(a => a.id !== "-1") &&
                                                        recipients.find(a => a.id !== "-2") &&
                                                        recipients.find(a => a.id !== "-3") &&
                                                        recipients.find(a => a.id !== "-4")
                                                        ? () => {
                                                            if (recipients.find(a => a.id !== "-1") ||
                                                                recipients.find(a => a.id !== "-2") ||
                                                                recipients.find(a => a.id !== "-3") ||
                                                                recipients.find(a => a.id !== "-4")) {
                                                                let newRecipients = recipients.filter(a => a.id !== option.id);
                                                                if (newRecipients.length === 0) {
                                                                    if (notifyEvent ===
                                                                        "created" ||
                                                                        notifyEvent ===
                                                                            "created_or_updated") {
                                                                        newRecipients.push({
                                                                            first_name: t("notifications.notification-option-form.assigned-to"),
                                                                            id: "-3",
                                                                        });
                                                                    }
                                                                    else if (notifyEvent === "updated") {
                                                                        newRecipients.push({
                                                                            first_name: t("notifications.notification-option-form.created-by"),
                                                                            id: "-2",
                                                                        });
                                                                    }
                                                                    else {
                                                                        newRecipients.push({
                                                                            first_name: t("notifications.notification-option-form.updated-by"),
                                                                            id: "-1",
                                                                        });
                                                                    }
                                                                }
                                                                setRecipients(newRecipients);
                                                            }
                                                        }
                                                        : undefined }, option.id));
                                            }), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("notifications.notification-option-form.to") +
                                                    " " +
                                                    t("notifications.notification-option-form.recipients").toLocaleLowerCase(), required: true, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(InputAdornment, Object.assign({ position: "end", style: {
                                                            position: "absolute",
                                                            right: "16px",
                                                        } }, { children: [searchUsers.length > 0 && (_jsx(Fragment, { children: isUsersLoading && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => setSearchUsers(""), size: "large" }, { children: _jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5 }) }))) })), _jsx(IconButton, Object.assign({ disabled: isUsersLoading, edge: "end", onClick: () => setShowUserFilters(true), size: "large" }, { children: _jsx(FilterIcon, { color: !isUsersLoading
                                                                        ? "action"
                                                                        : "disabled" }) }))] }))) }) }))) }) }))), type === "user" && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", checked: assignedToMe, onChange: () => setAssignedToMe(!assignedToMe) }), 
                                                // @ts-ignore
                                                label: t("notifications.notification-option-form.assigned-to-me") }) }) }))), (notifyEvent === "created" ||
                                        notifyEvent === "updated" ||
                                        notifyEvent === "created_or_updated") &&
                                        recipients.find(a => a.id !== "-4") && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: _jsx(Alert, Object.assign({ severity: "warning" }, { children: type === "tenant"
                                                ? t("notifications.notification-option-form.user-triggered")
                                                : t("notifications.notification-option-form.i-triggered") })) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: _jsx(Box, Object.assign({ sx: { paddingY: 1 } }, { children: _jsx(Divider, {}) })) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Typography, { children: t("notifications.notification-option-form.type") }), _jsx(FormControl, Object.assign({ style: { marginRight: 12 } }, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", checked: viaWeb, onChange: () => setViaWeb(!viaWeb), disabled: viaDisabled }), 
                                                    // @ts-ignore
                                                    label: t("notifications.notifications-table.via-web") }) })), _jsx(FormControl, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { color: "secondary", checked: viaEmail, onChange: () => setViaEmail(!viaEmail), disabled: viaDisabled }), 
                                                    // @ts-ignore
                                                    label: t("notifications.notifications-table.via-email") }) }), viaEmail && (_jsx(Box, Object.assign({ sx: {
                                                    marginTop: 2,
                                                } }, { children: _jsx(InputCheckbox, { label: t("notifications.notifications-table.content"), required: false, options: emailOptions, checkedValues: checkedEmailOptions, setCheckedValues: setCheckedEmailOptions }) })))] })), (notifyEvent === "scheduled" || notifyEvent === "due") && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("notifications.notifications-table.alert") }), _jsx(Select, Object.assign({ label: t("notifications.notifications-table.alert"), value: alertAdvanceMinutes, onChange: event => setAlertAdvanceMinutes(event.target.value) }, { children: alertAdvanceOptions.map(option => {
                                                        return (_jsx(MenuItem, Object.assign({ value: option }, { children: option === 0
                                                                ? notifyEvent === "scheduled"
                                                                    ? t("notifications.notification-option-form.time.at-scheduled-date-time")
                                                                    : t("notifications.notification-option-form.time.at-due-date-time")
                                                                : option === 5
                                                                    ? "5 " +
                                                                        t("notifications.notification-option-form.time.minutes-before")
                                                                    : option === 10
                                                                        ? "10 " +
                                                                            t("notifications.notification-option-form.time.minutes-before")
                                                                        : option === 15
                                                                            ? "15 " +
                                                                                t("notifications.notification-option-form.time.minutes-before")
                                                                            : option === 30
                                                                                ? "30 " +
                                                                                    t("notifications.notification-option-form.time.minutes-before")
                                                                                : option === 60
                                                                                    ? t("notifications.notification-option-form.time.1-hour")
                                                                                    : option === 120
                                                                                        ? t("notifications.notification-option-form.time.2-hours")
                                                                                        : option === 1440
                                                                                            ? t("notifications.notification-option-form.time.1-day")
                                                                                            : option === 2880
                                                                                                ? t("notifications.notification-option-form.time.2-days")
                                                                                                : t("notifications.notification-option-form.time.1-week") }), option));
                                                    }) }))] })) }))), checkedEmailOptions.some(option => option === "custom_subject") && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputText, { label: t("notifications.notification-option-form.extended-email-options.custom-subject") + " *", required: true, value: customEmailSubject, setValue: setCustomEmailSubject }) }))), checkedEmailOptions.some(option => option === "custom_content") && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputEditor, { value: customEmailContent, setValue: setCustomEmailContent }) }))), (checkedEmailOptions.some(option => option === "custom_subject") ||
                                        checkedEmailOptions.some(option => option === "custom_content")) && (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Alert, Object.assign({ severity: "info", sx: {
                                                marginTop: 2,
                                            } }, { children: [_jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: t("notifications.notification-option-form.email-placeholders") })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::contact_first_name::" })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::contact_last_name::" })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::contact_company::" })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::ticket_id::" })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::ticket_status::" })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::ticket_priority::" })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::ticket_scheduled_date::" })), _jsx(Typography, Object.assign({ variant: "caption", component: "p" }, { children: "::ticket_due_date::" }))] })) })))] }))] }), _jsx(DialogActions, { children: _jsxs(Box, Object.assign({ sx: { paddingRight: 2 } }, { children: [status === "edit" && (_jsx(Button, Object.assign({ style: { color: "rgb(244, 67, 54)", float: "left" }, onClick: () => {
                                        if (setIdToDelete && option)
                                            setIdToDelete(option.id);
                                    } }, { children: t("notifications.notifications-table.delete-notification-option") }))), _jsx(LoadingButton, Object.assign({ type: "button", disabled: (recipients.length === 0 && type === "tenant") ||
                                        (checkedEmailOptions.some(option => option === "custom_subject") &&
                                            customEmailSubject.length === 0) ||
                                        (checkedEmailOptions.some(option => option === "custom_content") &&
                                            customEmailContent.length === 0), variant: "text", color: "primary", loading: isSaving, onClick: () => status === "new"
                                        ? createNotificationOption()
                                        : updateNotificationOption(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                        ? t("notifications.notifications-table.create")
                                        : t("notifications.notifications-table.update") }))] })) })] })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showUserFilters, onClose: () => {
                    setShowUserFilters(false);
                } }, { children: [_jsx(DialogTitle, { children: t("filters.search-users-by") }), _jsx(DialogContent, { children: _jsx(InputRadio, { required: false, type: "text", direction: "vertical", options: [
                                {
                                    value: "full_name",
                                    label: t("users.user-attributes.full-name"),
                                },
                                {
                                    value: "email",
                                    label: t("users.user-attributes.email"),
                                },
                                {
                                    value: "username",
                                    label: t("users.user-attributes.username"),
                                },
                                {
                                    value: "id",
                                    label: "ID",
                                },
                            ], value: searchUsersBy ? searchUsersBy : "full_name", setValue: setSearchUsersBy }) }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: () => {
                                setShowUserFilters(false);
                            } }, { children: t("close") })) })] }))] }));
}
