import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import InputRadio from "../../inputs/input-radio";
import InputDatetime from "../../inputs/input-datetime";
import { useApiTenant } from "../../../../api/api-tenant";
import { cleanText } from "../../../../helpers/clean";
export default function AudienceLeadsFilters({ showFilters, filterPhoneCountry, setFilterPhoneCountry, filterPhoneArea, setFilterPhoneArea, filterStatus, setFilterStatus, filterAssignees, setFilterAssignees, filterContacts, setFilterContacts, filterUpdatedAtStartDate, setFilterUpdatedAtStartDate, filterUpdatedAtEndDate, setFilterUpdatedAtEndDate, filterUpdatedAtStartTime, setFilterUpdatedAtStartTime, filterUpdatedAtEndTime, setFilterUpdatedAtEndTime, setFilter, areFieldsAndGroupsLoaded, setAreFieldsAndGroupsLoaded, phoneCountries, setPhoneCountries, phoneAreas, setPhoneAreas, }) {
    const { t } = useTranslation();
    const api = useApiTenant();
    const [statuses] = useState(["all", "completed", "pending", "invalid", "checking"]);
    const [users, setUsers] = useState([]);
    const [searchUsers, setSearchUsers] = useState("");
    const [searchUsersBy, setSearchUsersBy] = useState("full_name");
    const [contacts, setContacts] = useState([]);
    const [searchContacts, setSearchContacts] = useState("");
    const [searchContactsBy, setSearchContactsBy] = useState("full_name,company");
    const [showContactFilters, setShowContactFilters] = useState(false);
    const [showUserFilters, setShowUserFilters] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [isContactsLoading, setIsContactsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    useEffect(() => {
        if (!showFilters)
            return;
        if (areFieldsAndGroupsLoaded)
            return;
        setIsLoading2(true);
        setIsLoading(true);
        api.GET_phoneCountries()
            .then(response => {
            setPhoneCountries(response.data.phone_countries);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        api.GET_phoneAreas()
            .then(response => {
            setPhoneAreas(response.data.phone_areas);
            setIsLoading2(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        setAreFieldsAndGroupsLoaded(true);
        return () => api.cancel();
    }, [showFilters]);
    useEffect(() => {
        setContacts([]);
        if (searchContacts.length === 0)
            return;
        setIsContactsLoading(true);
        debounceSearch(cleanText(searchContacts), filterContacts, searchContactsBy);
    }, [searchContacts]);
    const getContacts = (search, filterContacts, searchContactsBy) => {
        setIsContactsLoading(true);
        api.GET_contacts(25, 1, "id", "asc", search, searchContactsBy)
            .then(response => {
            let contacts = response.data.contacts;
            if (filterContacts.length > 0) {
                contacts.push(...filterContacts);
                contacts = [...new Set(contacts)];
            }
            setContacts(contacts);
            setIsContactsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsContactsLoading(false);
            }
        });
    };
    const debounceSearch = useCallback(debounce((search, filterContacts, searchContactsBy) => {
        getContacts(search, filterContacts, searchContactsBy);
    }, 700), []);
    useEffect(() => {
        if (filterUpdatedAtStartDate === "")
            setFilterUpdatedAtStartTime("");
        if (filterUpdatedAtEndDate === "")
            setFilterUpdatedAtEndTime("");
    }, [filterUpdatedAtStartDate, filterUpdatedAtEndDate]);
    useEffect(() => {
        setUsers([]);
        if (searchUsers.length === 0)
            return;
        setIsUsersLoading(true);
        debounceSearchUsers(cleanText(searchUsers), searchUsersBy);
    }, [searchUsers]);
    const debounceSearchUsers = useCallback(debounce((search, searchUsersBy) => {
        getUsers(search, searchUsersBy);
    }, 700), []);
    const getUsers = (search, searchUsersBy) => {
        api.GET_users(25, 1, "id", "asc", search, searchUsersBy)
            .then(response => {
            setUsers(response.data.tenant_users);
            setIsUsersLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsUsersLoading(false);
            }
        });
    };
    return (_jsxs(Fragment, { children: [_jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showFilters }, { children: [_jsx(DialogTitle, { children: t("filters.filter-by") }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("campaigns.audiences.leads.lead-attributes.status") }), _jsx(Select, Object.assign({ label: t("campaigns.audiences.leads.lead-attributes.status"), value: filterStatus !== "all" ? filterStatus : "", onChange: event => setFilterStatus(event.target.value) }, { children: statuses.map(status => {
                                                    return (_jsx(MenuItem, Object.assign({ value: status }, { children: status === "all"
                                                            ? t("campaigns.audiences.leads.lead-attributes.status-types.all")
                                                            : status === "pending"
                                                                ? t("campaigns.audiences.leads.lead-attributes.status-types.pending")
                                                                : status === "invalid"
                                                                    ? t("campaigns.audiences.leads.lead-attributes.status-types.invalid")
                                                                    : status === "completed"
                                                                        ? t("campaigns.audiences.leads.lead-attributes.status-types.completed")
                                                                        : t("campaigns.audiences.leads.lead-attributes.status-types.checking") }), status));
                                                }) }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { multiple: true, options: contacts, getOptionLabel: contact => contact.type
                                            ? contact.type === "business"
                                                ? contact.company
                                                    ? contact.company
                                                    : contact.is_anonymous === true
                                                        ? t("contacts.anonymous")
                                                        : contact.first_name || contact.last_name
                                                            ? (contact.first_name ? contact.first_name : "") +
                                                                (contact.last_name ? " " + contact.last_name : "")
                                                            : ""
                                                : contact.is_anonymous === true
                                                    ? t("contacts.anonymous")
                                                    : contact.first_name || contact.last_name
                                                        ? (contact.first_name ? contact.first_name : "") +
                                                            (contact.last_name ? " " + contact.last_name : "")
                                                        : contact.company
                                                            ? contact.company
                                                            : ""
                                            : contact.company
                                                ? contact.company
                                                : contact.is_anonymous === true
                                                    ? t("contacts.anonymous")
                                                    : contact.first_name || contact.last_name
                                                        ? (contact.first_name ? contact.first_name : "") +
                                                            (contact.last_name ? " " + contact.last_name : "")
                                                        : "", filterSelectedOptions: true, filterOptions: options => options, isOptionEqualToValue: (option, value) => option.id === value.id, value: filterContacts, onChange: (event, value) => {
                                            setFilterContacts(value);
                                        }, noOptionsText: t("campaigns.audiences.leads.leads-filters.search-contacts-(by") +
                                            (searchContactsBy === "full_name,company"
                                                ? t("contacts.contact-attributes.full-name-company").toLocaleLowerCase()
                                                : searchContactsBy === "full_name"
                                                    ? t("contacts.contact-attributes.full-name").toLocaleLowerCase()
                                                    : searchContactsBy === "company"
                                                        ? t("contacts.contact-attributes.company").toLocaleLowerCase()
                                                        : searchContactsBy === "phones"
                                                            ? t("contacts.contact-attributes.phones").toLocaleLowerCase()
                                                            : searchContactsBy === "emails"
                                                                ? t("contacts.contact-attributes.emails").toLocaleLowerCase()
                                                                : searchContactsBy === "address"
                                                                    ? t("contacts.contact-attributes.address").toLocaleLowerCase()
                                                                    : searchContactsBy === "oib"
                                                                        ? "OIB"
                                                                        : "ID") +
                                            ")", onInputChange: (event, value) => setSearchContacts(value), renderOption: (props, contact) => {
                                            var _a, _b, _c, _d;
                                            return (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                                display: "flex",
                                                            } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: contact.type
                                                                    ? contact.type === "business"
                                                                        ? contact.company
                                                                            ? contact.company
                                                                            : contact.is_anonymous === true
                                                                                ? t("contacts.anonymous")
                                                                                : contact.first_name ||
                                                                                    contact.last_name
                                                                                    ? (contact.first_name
                                                                                        ? contact.first_name
                                                                                        : "") +
                                                                                        (contact.last_name
                                                                                            ? " " + contact.last_name
                                                                                            : "")
                                                                                    : ""
                                                                        : contact.is_anonymous === true
                                                                            ? t("contacts.anonymous")
                                                                            : contact.first_name ||
                                                                                contact.last_name
                                                                                ? (contact.first_name
                                                                                    ? contact.first_name
                                                                                    : "") +
                                                                                    (contact.last_name
                                                                                        ? " " + contact.last_name
                                                                                        : "")
                                                                                : contact.company
                                                                                    ? contact.company
                                                                                    : ""
                                                                    : contact.company
                                                                        ? contact.company
                                                                        : contact.is_anonymous === true
                                                                            ? t("contacts.anonymous")
                                                                            : contact.first_name || contact.last_name
                                                                                ? (contact.first_name
                                                                                    ? contact.first_name
                                                                                    : "") +
                                                                                    (contact.last_name
                                                                                        ? " " + contact.last_name
                                                                                        : "")
                                                                                : "" })) })), contact.type ? (contact.type === "business" ? (contact.company ? (_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: contact.first_name || contact.last_name
                                                                ? ((_a = contact.first_name) !== null && _a !== void 0 ? _a : "") +
                                                                    " " +
                                                                    ((_b = contact.last_name) !== null && _b !== void 0 ? _b : "")
                                                                : t("contacts.anonymous") }))) : ("")) : contact.company &&
                                                            (contact.first_name || contact.last_name) ? (_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: contact.company }))) : contact.company &&
                                                            !(contact.first_name || contact.last_name) ? (_jsx(Typography, { children: t("contacts.anonymous") })) : ("")) : contact.company ? (_jsx(Typography, Object.assign({ variant: "subtitle1" }, { children: contact.first_name || contact.last_name
                                                                ? ((_c = contact.first_name) !== null && _c !== void 0 ? _c : "") +
                                                                    " " +
                                                                    ((_d = contact.last_name) !== null && _d !== void 0 ? _d : "")
                                                                : t("contacts.anonymous") }))) : ("")] }), contact.id) })));
                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("contacts.contact"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(InputAdornment, Object.assign({ position: "end", style: {
                                                        position: "absolute",
                                                        right: "16px",
                                                    } }, { children: [searchContacts.length > 0 && (_jsx(Fragment, { children: isContactsLoading && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => setSearchContacts(""), size: "large" }, { children: _jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5 }) }))) })), _jsx(IconButton, Object.assign({ disabled: isContactsLoading, edge: "end", onClick: () => setShowContactFilters(true), size: "large" }, { children: _jsx(FilterIcon, { color: !isContactsLoading
                                                                    ? "action"
                                                                    : "disabled" }) }))] }))) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { multiple: true, options: users, getOptionLabel: user => (user.first_name ? user.first_name + " " : "") +
                                            (user.last_name ? user.last_name + " " : "") +
                                            (user.email ? "(" + user.email + ") " : "") +
                                            (user.username ? "(" + user.username + ")" : ""), isOptionEqualToValue: (option, value) => option.global_id === value.global_id, value: filterAssignees, onChange: (event, value) => {
                                            setFilterAssignees(value);
                                        }, loading: isUsersLoading, filterSelectedOptions: true, filterOptions: options => options, noOptionsText: t("filters.search-assignees-by") +
                                            " (" +
                                            (searchUsersBy === "full_name"
                                                ? t("users.user-attributes.full-name").toLocaleLowerCase()
                                                : searchUsersBy === "email"
                                                    ? t("users.user-attributes.email").toLocaleLowerCase()
                                                    : searchUsersBy === "username"
                                                        ? t("users.user-attributes.username").toLocaleLowerCase()
                                                        : "ID") +
                                            ")", onInputChange: (event, value) => setSearchUsers(value), renderOption: (props, user) => (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: (user.first_name
                                                                ? user.first_name + " "
                                                                : "") +
                                                                (user.last_name
                                                                    ? user.last_name + " "
                                                                    : "") })) })), _jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: user.email || user.username })) }))] }), user.id) }))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("campaigns.audiences.leads.lead-attributes.assignee"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(InputAdornment, Object.assign({ position: "end", style: {
                                                        position: "absolute",
                                                        right: "16px",
                                                    } }, { children: [searchUsers.length > 0 && (_jsx(Fragment, { children: isUsersLoading && (_jsx(IconButton, Object.assign({ edge: "end", onClick: () => setSearchUsers(""), size: "large" }, { children: _jsx(CircularProgress, { color: "inherit", size: 18, thickness: 5 }) }))) })), _jsx(IconButton, Object.assign({ disabled: isUsersLoading, edge: "end", onClick: () => setShowUserFilters(true), size: "large" }, { children: _jsx(FilterIcon, { color: !isUsersLoading
                                                                    ? "action"
                                                                    : "disabled" }) }))] }))) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { options: phoneCountries, getOptionLabel: country => country.phone + " " + country.name + " (" + country.code + ")", isOptionEqualToValue: (option, value) => option.code === value.code, value: filterPhoneCountry, onChange: (event, value) => {
                                            setFilterPhoneCountry(value);
                                            setFilterPhoneArea(null);
                                        }, loading: isLoading, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("options.phone-countries.phone-country"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { disabled: !filterPhoneCountry, options: phoneAreas, getOptionLabel: area => area.phone + " - " + area.name + " [" + area.id + "]", getOptionDisabled: area => area.phone_country_id !== (filterPhoneCountry === null || filterPhoneCountry === void 0 ? void 0 : filterPhoneCountry.id), isOptionEqualToValue: (option, value) => option.id === value.id, value: filterPhoneArea, onChange: (event, value) => {
                                            setFilterPhoneArea(value);
                                        }, loading: isLoading2, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("options.phone-areas.phone-area"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isLoading2 ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(InputDatetime, { type: "date", label: t("campaigns.audiences.leads.leads-filters.updated-at-start"), required: false, value: filterUpdatedAtStartDate, setValue: setFilterUpdatedAtStartDate }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(InputDatetime, { type: "time", label: "(" + t("tickets.ticket-filters.time") + ")", required: false, value: filterUpdatedAtStartTime !== ""
                                            ? filterUpdatedAtStartTime
                                            : null, status: "start", setValue: setFilterUpdatedAtStartTime, disabled: !filterUpdatedAtStartDate || filterUpdatedAtStartDate === "" }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(InputDatetime, { type: "date", label: t("campaigns.audiences.leads.leads-filters.updated-at-end"), required: false, value: filterUpdatedAtEndDate, setValue: setFilterUpdatedAtEndDate }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(InputDatetime, { type: "time", label: "(" + t("tickets.ticket-filters.time") + ")", required: false, value: filterUpdatedAtEndTime !== "" ? filterUpdatedAtEndTime : null, status: "end", setValue: setFilterUpdatedAtEndTime, disabled: !filterUpdatedAtEndDate || filterUpdatedAtEndDate === "" }) }))] })) }), _jsx(DialogActions, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "text", color: "primary", loading: isLoading, disabled: isLoading, onClick: () => setFilter(true), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: t("filters.apply") })) })] })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showContactFilters, onClose: () => {
                    setShowContactFilters(false);
                } }, { children: [_jsx(DialogTitle, { children: t("campaigns.audiences.leads.leads-filters.search-contacts-by") }), _jsx(DialogContent, { children: _jsx(InputRadio, { required: false, type: "text", direction: "vertical", options: [
                                {
                                    value: "full_name,company",
                                    label: t("contacts.contact-attributes.full-name-company"),
                                },
                                {
                                    value: "full_name",
                                    label: t("contacts.contact-attributes.full-name"),
                                },
                                {
                                    value: "company",
                                    label: t("contacts.contact-attributes.company"),
                                },
                                {
                                    value: "phones",
                                    label: t("contacts.contact-attributes.phones"),
                                },
                                {
                                    value: "emails",
                                    label: t("contacts.contact-attributes.emails"),
                                },
                                {
                                    value: "address",
                                    label: t("contacts.contact-attributes.address"),
                                },
                                {
                                    value: "oib",
                                    label: "OIB",
                                },
                                {
                                    value: "id",
                                    label: "ID",
                                },
                            ], value: searchContactsBy ? searchContactsBy : "", setValue: setSearchContactsBy }) }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: () => {
                                setShowContactFilters(false);
                            } }, { children: t("close") })) })] })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: showUserFilters, onClose: () => {
                    setShowUserFilters(false);
                } }, { children: [_jsx(DialogTitle, { children: t("filters.search-assignees-by") }), _jsx(DialogContent, { children: _jsx(InputRadio, { required: false, type: "text", direction: "vertical", options: [
                                {
                                    value: "full_name",
                                    label: t("users.user-attributes.full-name"),
                                },
                                {
                                    value: "email",
                                    label: t("users.user-attributes.email"),
                                },
                                {
                                    value: "username",
                                    label: t("users.user-attributes.username"),
                                },
                                {
                                    value: "id",
                                    label: "ID",
                                },
                            ], value: searchUsersBy ? searchUsersBy : "full_name", setValue: setSearchUsersBy }) }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: () => {
                                setShowUserFilters(false);
                            } }, { children: t("close") })) })] }))] }));
}
