import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Settings";
import FilteredIcon from "@mui/icons-material/FilterAlt";
import Table from "../blocks/table";
import CustomFieldsView from "../custom-fields/custom-fields-view";
import UserFilters from "./user-filters";
import { useAlert } from "../../../services/alert";
import { useApiCentral } from "../../../api/api-central";
import { useApiTenant } from "../../../api/api-tenant";
import { copy } from "../../../helpers/copy";
export default function UsersTable({ type, title, link }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const api = type == "client" ? useApiTenant() : useApiCentral();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [tableQuery, setTableQuery] = useState();
    const [showFilters, setShowFilters] = useState(false);
    const [searchBy, setSearchBy] = useState(null);
    const [filterRole, setFilterRole] = useState(null);
    const [filterGroups, setFilterGroups] = useState([]);
    const [filter, setFilter] = useState(false);
    const [groups, setGroups] = useState([]);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [relatedGroups, setRelatedGroups] = useState([]);
    const [areGroupsLoaded, setAreGroupsLoaded] = useState(false);
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (type === "central" || !filter)
            return;
        if (isLoading)
            return;
        resetTablePageAndRefreshData();
        setFilter(false);
    }, [filter]);
    const getTableData = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_users(query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : null, query.orderDirection ? query.orderDirection : null, query.search ? query.search : null, searchBy && query.search ? searchBy : undefined, filterRole ? filterRole : undefined, filterGroups.length > 0 ? filterGroups.map(group => group.id) : undefined)
                .then(response => {
                resolve({
                    data: type === "client"
                        ? response.data.tenant_users
                        : response.data.central_users,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
                setShowFilters(false);
                setIsLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoading(false);
                }
            });
        });
    };
    const refreshTableData = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    const resetTablePageAndRefreshData = () => {
        var _a;
        setIsLoading(true);
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onChangePage(null, 0);
    };
    const userGroups = (groups, level, count) => {
        return groups.map((group, index) => {
            let children = null;
            count++;
            if (group.groups && group.groups.length > 0) {
                children = userGroups(group.groups, level + 1, count);
            }
            return (_jsxs(Fragment, { children: [level == 0 ? (_jsxs(Fragment, { children: [count > 1 && (_jsx(Box, Object.assign({ sx: {
                                    paddingBottom: 1,
                                } }, { children: _jsx(Divider, { light: true }) }))), _jsx(Chip, { style: {
                                    marginRight: 6,
                                    marginBottom: 10,
                                }, size: "small", color: "secondary", label: group.name })] })) : (_jsx(Chip, { style: { marginRight: 6, marginBottom: 10 }, size: "small", label: group.name })), _jsx(Fragment, { children: children })] }, index));
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Table, { title: title, actions: [
                    {
                        icon: () => _jsx(ViewIcon, { color: "action" }),
                        tooltip: t("users.view-user"),
                        onClick: (event, data) => navigate(link + data.id),
                    },
                    {
                        icon: () => _jsx(EditIcon, { color: "action" }),
                        tooltip: t("users.edit-user"),
                        onClick: (event, data) => navigate(link + data.id + "/edit"),
                    },
                    type === "client"
                        ? {
                            icon: () => _jsx(FilteredIcon, { color: "action" }),
                            tooltip: t("users.users-table.filter-users"),
                            isFreeAction: true,
                            onClick: () => setShowFilters(true),
                        }
                        : undefined,
                    {
                        icon: () => _jsx(RefreshIcon, { color: "action" }),
                        tooltip: t("users.users-table.refresh-users"),
                        isFreeAction: true,
                        onClick: () => refreshTableData(),
                    },
                ], columns: isMobile
                    ? [
                        {
                            title: t("users.user") + " #",
                            field: "id",
                            defaultSort: "desc",
                        },
                    ]
                    : [
                        {
                            maxWidth: "80px",
                            title: "#",
                            field: "id",
                            type: "numeric",
                            defaultSort: "desc",
                        },
                        {
                            maxWidth: "80px",
                            title: t("users.user-attributes.active"),
                            field: "is_enabled",
                            sorting: false,
                            hidden: type !== "central",
                            render: user => {
                                return (_jsx(Chip, { label: user.is_enabled ? t("yes") : t("no"), size: "small", variant: "filled", color: user.is_enabled ? "success" : "error" }));
                            },
                        },
                        {
                            title: t("users.user-attributes.first-name"),
                            field: "first_name",
                        },
                        {
                            title: t("users.user-attributes.last-name"),
                            field: "last_name",
                        },
                        {
                            title: t("users.user-attributes.email"),
                            field: "email",
                            render: user => {
                                if (!user.email)
                                    return "";
                                return (_jsx(Chip, { label: user.email, variant: "outlined", size: "small", onDelete: () => {
                                        copy(user.email);
                                        alert.show(t("email-copied"), "info");
                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                        // @ts-ignore
                                        title: t("copy-email") }, { children: _jsx(CopyContentIcon, {}) })) }));
                            },
                        },
                        {
                            title: t("users.user-attributes.username"),
                            field: "username",
                            render: user => {
                                if (!user.username)
                                    return "";
                                return (_jsx(Chip, { label: user.username, variant: "outlined", size: "small", onDelete: () => {
                                        copy(user.username);
                                        alert.show(t("username-copied"), "info");
                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                        // @ts-ignore
                                        title: t("copy-username") }, { children: _jsx(CopyContentIcon, {}) })) }));
                            },
                        },
                        {
                            title: t("users.role"),
                            sorting: false,
                            render: user => {
                                return user.roles[0] != null ? (_jsx(Chip, { label: user.roles[0].name === "admin"
                                        ? t("users.roles.admin")
                                        : user.roles[0].name === "manager"
                                            ? t("users.roles.manager")
                                            : user.roles[0].name === "super_admin"
                                                ? t("users.roles.super-admin")
                                                : user.roles[0].name === "user"
                                                    ? t("users.roles.user")
                                                    : t("users.roles.agent"), size: "small", style: {
                                        border: "1px solid " +
                                            (user.roles[0].name === "admin"
                                                ? "rgb(244, 67, 54, 1)"
                                                : user.roles[0].name === "manager"
                                                    ? "rgb(255, 152, 0, 1)"
                                                    : user.roles[0].name === "super_admin"
                                                        ? "rgb(220, 0, 78, 1)"
                                                        : "rgb(33, 150, 243, 1)"),
                                        backgroundColor: user.roles[0].name === "admin"
                                            ? "rgb(244, 67, 54, 0.2)"
                                            : user.roles[0].name === "manager"
                                                ? "rgb(255, 152, 0, 0.2)"
                                                : user.roles[0].name === "super_admin"
                                                    ? "rgb(220, 0, 78, 0.2)"
                                                    : "rgb(33, 150, 243, 0.2)",
                                    } })) : ("");
                            },
                        },
                        {
                            title: t("updated-at"),
                            field: "updated_at",
                            width: "150px",
                            render: user => {
                                return new Date(Date.parse(user.updated_at)).toLocaleDateString("en-GB");
                            },
                        },
                    ], detailPanel: (user) => {
                    return (_jsxs(Box, Object.assign({ sx: {
                            paddingX: isMobile ? 2 : 6,
                            paddingY: 3,
                        } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [isMobile && type === "central" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.active") })), _jsx(Chip, { label: user.is_enabled ? t("yes") : t("no"), size: "small", variant: "filled", color: user.is_enabled ? "success" : "error" })] }))), isMobile && user.first_name && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.first-name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: user.first_name }))] }))), isMobile && user.last_name && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.last-name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: user.last_name }))] }))), isMobile && user.roles.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.role") })), _jsx(Box, Object.assign({ sx: {
                                                    paddingTop: 0.5,
                                                } }, { children: _jsx(Chip, { label: user.roles[0].name === "admin"
                                                        ? t("users.roles.admin")
                                                        : user.roles[0].name === "manager"
                                                            ? t("users.roles.manager")
                                                            : user.roles[0].name === "super_admin"
                                                                ? t("users.roles.super-admin")
                                                                : user.roles[0].name === "user"
                                                                    ? t("users.roles.user")
                                                                    : t("users.roles.agent"), size: "small", style: {
                                                        border: "1px solid " +
                                                            (user.roles[0].name === "admin"
                                                                ? "rgb(244, 67, 54, 1)"
                                                                : user.roles[0].name === "manager"
                                                                    ? "rgb(255, 152, 0, 1)"
                                                                    : user.roles[0].name === "super_admin"
                                                                        ? "rgb(220, 0, 78, 1)"
                                                                        : "rgb(33, 150, 243, 1)"),
                                                        backgroundColor: user.roles[0].name === "admin"
                                                            ? "rgb(244, 67, 54, 0.2)"
                                                            : user.roles[0].name === "manager"
                                                                ? "rgb(255, 152, 0, 0.2)"
                                                                : user.roles[0].name === "super_admin"
                                                                    ? "rgb(220, 0, 78, 0.2)"
                                                                    : "rgb(33, 150, 243, 0.2)",
                                                    } }) }))] }))), isMobile && user.email && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.email") })), _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    paddingTop: 0.5,
                                                } }, { children: _jsx(Chip, { style: {
                                                        display: "flex",
                                                        overflow: "hidden",
                                                    }, label: user.email, variant: "outlined", size: "small", onDelete: () => {
                                                        copy(user.email);
                                                        alert.show(t("email-copied"), "info");
                                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                        // @ts-ignore
                                                        title: t("copy-email") }, { children: _jsx(CopyContentIcon, {}) })) }) }))] }))), isMobile && user.username && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.user-attributes.email") })), _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    paddingTop: 0.5,
                                                } }, { children: _jsx(Chip, { style: {
                                                        display: "flex",
                                                        overflow: "hidden",
                                                    }, label: user.username, variant: "outlined", size: "small", onDelete: () => {
                                                        copy(user.username);
                                                        alert.show(t("username-copied"), "info");
                                                    }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                        // @ts-ignore
                                                        title: t("copy-username") }, { children: _jsx(CopyContentIcon, {}) })) }) }))] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("created-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(user.created_at)).toLocaleDateString("en-GB") }))] })), isMobile && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("updated_at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(user.updated_at)).toLocaleDateString("en-GB") }))] })))] })), type === "client" && user.custom_fields.length > 0 && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                            paddingY: 4,
                                        } }, { children: _jsx(Divider, { light: true }) })), _jsx(CustomFieldsView, { status: "table_view", type: "tenant_users", customFields: user.custom_fields })] })), user.groups.length > 0 && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                            paddingY: 4,
                                        } }, { children: _jsx(Divider, { light: true }) })), _jsxs(Grid, Object.assign({ container: true, direction: "column", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 1 }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("groups.groups") })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: userGroups(user.groups, 0, 0) }))] }))] }))] })));
                }, data: query => getTableData(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }), type === "client" && (_jsx(UserFilters, { showFilters: showFilters, searchBy: searchBy, setSearchBy: setSearchBy, filterRole: filterRole, setFilterRole: setFilterRole, filterGroups: filterGroups, setFilterGroups: setFilterGroups, isLoading: isLoading, setFilter: setFilter, groups: groups, setGroups: setGroups, sortedGroups: sortedGroups, setSortedGroups: setSortedGroups, relatedGroups: relatedGroups, setRelatedGroups: setRelatedGroups, areGroupsLoaded: areGroupsLoaded, setAreGroupsLoaded: setAreGroupsLoaded }))] }));
}
