import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import Hidden from "@mui/material/Hidden";
import InputAdornment from "@mui/material/InputAdornment";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddBoxIcon from "@mui/icons-material/AddBox";
import StatusIcon from "@mui/icons-material/Timelapse";
import PriorityIcon from "@mui/icons-material/Timer";
import ActivityIcon from "@mui/icons-material/Update";
import InputIcon from "@mui/icons-material/Input";
import DescriptionIcon from "@mui/icons-material/Description";
import InputText from "../inputs/input-text";
import InputTextArea from "../inputs/input-textarea";
import InputSlider from "../inputs/input-slider";
import InputEditor from "../inputs/input-editor";
import InputSwitch from "../inputs/input-switch";
import { useTenancy } from "../../../services/tenancy";
import { useAlert } from "../../../services/alert";
import { useApiTenant } from "../../../api/api-tenant";
import { flatGroups, sortGroups, relatedChildGroups, relatedParentGroups, groupInGroups, } from "../../../helpers/groups";
import { cleanSlug } from "../../../helpers/clean";
export default function CampaignForm({ status, campaign, campaignType }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tenancy = useTenancy();
    const alert = useAlert();
    const api = useApiTenant();
    const [name, setName] = useState((_a = campaign === null || campaign === void 0 ? void 0 : campaign.name) !== null && _a !== void 0 ? _a : "");
    const [description, setDescription] = useState((_b = campaign === null || campaign === void 0 ? void 0 : campaign.description) !== null && _b !== void 0 ? _b : "");
    const [queues, setQueues] = useState((_c = campaign === null || campaign === void 0 ? void 0 : campaign.queues) !== null && _c !== void 0 ? _c : []);
    const [welcome, setWelcome] = useState((_d = campaign === null || campaign === void 0 ? void 0 : campaign.instructions) !== null && _d !== void 0 ? _d : "");
    const [farewellMessage, setFarewellMessage] = useState((_e = campaign === null || campaign === void 0 ? void 0 : campaign.farewell_message) !== null && _e !== void 0 ? _e : '<p style="text-align: center; margin-bottom: 0;"><img src="/check.svg" width="80" height="80" alt="Check"/></p><p style="text-align: center;">Thank you for taking the time to complete this survey.</p>');
    const [type, setType] = useState((campaign === null || campaign === void 0 ? void 0 : campaign.type) ? campaign === null || campaign === void 0 ? void 0 : campaign.type : campaignType ? campaignType : "inbound");
    const [allowMultipleSubmits, setAllowMultipleSubmits] = useState((_f = campaign === null || campaign === void 0 ? void 0 : campaign.allow_multiple_survey_submits) !== null && _f !== void 0 ? _f : false);
    const [requireCaptcha, setRequireCaptcha] = useState((_g = campaign === null || campaign === void 0 ? void 0 : campaign.require_captcha_on_survey) !== null && _g !== void 0 ? _g : false);
    const [language, setLanguage] = useState((_h = campaign === null || campaign === void 0 ? void 0 : campaign.language) !== null && _h !== void 0 ? _h : "en");
    const [slug, setSlug] = useState((_j = campaign === null || campaign === void 0 ? void 0 : campaign.slug) !== null && _j !== void 0 ? _j : undefined);
    const [statusOptions, setStatusOptions] = useState((_k = campaign === null || campaign === void 0 ? void 0 : campaign.ticket_statuses) !== null && _k !== void 0 ? _k : [
        {
            id: "-1",
            name: t("campaigns.campaign-attributes.status-types.opened"),
            type: "opened",
            index: 0,
        },
        {
            id: "-1",
            name: t("campaigns.campaign-attributes.status-types.pending"),
            type: "pending",
            index: 1,
        },
        {
            id: "-1",
            name: t("campaigns.campaign-attributes.status-types.resolved"),
            type: "resolved",
            index: 2,
        },
    ]);
    const [priorityOptions, setPriorityOptions] = useState((_l = campaign === null || campaign === void 0 ? void 0 : campaign.ticket_priorities) !== null && _l !== void 0 ? _l : [
        {
            id: "-1",
            name: t("campaigns.campaign-attributes.priority-types.high"),
            level: 1,
            index: 0,
        },
        {
            id: "-1",
            name: t("campaigns.campaign-attributes.priority-types.medium"),
            level: 50,
            index: 1,
        },
        {
            id: "-1",
            name: t("campaigns.campaign-attributes.priority-types.low"),
            level: 100,
            index: 2,
        },
    ]);
    const [activityOptions, setActivityOptions] = useState((_m = campaign === null || campaign === void 0 ? void 0 : campaign.ticket_activities) !== null && _m !== void 0 ? _m : [
        { name: t("campaigns.campaign-form.communication"), index: 0 },
    ]);
    const [active, setActive] = useState((_o = campaign === null || campaign === void 0 ? void 0 : campaign.active) !== null && _o !== void 0 ? _o : true);
    const [selectTypes] = useState(["inbound", "outbound", "contract", "survey"]);
    const [customFieldOptions, setCustomFieldOptions] = useState([
        (campaign === null || campaign === void 0 ? void 0 : campaign.custom_field_options) && campaign.custom_field_options.length > 0
            ? campaign === null || campaign === void 0 ? void 0 : campaign.custom_field_options
            : [],
        (campaign === null || campaign === void 0 ? void 0 : campaign.activity_custom_field_options) &&
            campaign.activity_custom_field_options.length > 0
            ? campaign === null || campaign === void 0 ? void 0 : campaign.activity_custom_field_options
            : [],
    ].flat());
    const [fieldOptions, setFieldOptions] = useState((campaign === null || campaign === void 0 ? void 0 : campaign.field_options) && campaign.field_options.length > 0
        ? campaign === null || campaign === void 0 ? void 0 : campaign.field_options
        : [
            { table: "contacts", data: "phones", required: true, visible: true },
            { table: "contacts", data: "emails", required: false, visible: true },
            { table: "contacts", data: "phones_or_emails", required: false, visible: true },
            { table: "contacts", data: "first_name", required: false, visible: true },
            { table: "contacts", data: "last_name", required: false, visible: true },
            { table: "contacts", data: "company", required: false, visible: true },
            { table: "contacts", data: "type", required: false, visible: false },
            { table: "contacts", data: "address", required: false, visible: false },
            { table: "contacts", data: "oib", required: false, visible: false },
            { table: "ticket_details", data: "assignee_ids", required: false, visible: true },
            {
                table: "ticket_details",
                data: "forwarded_call",
                required: false,
                visible: false,
            },
            {
                table: "ticket_details",
                data: "start_date_time",
                required: false,
                visible: true,
            },
            {
                table: "ticket_details",
                data: "due_date_time",
                required: false,
                visible: false,
            },
            { table: "ticket_details", data: "estimate", required: false, visible: false },
            { table: "ticket_details", data: "progress", required: false, visible: false },
            { table: "ticket_details", data: "notes", required: false, visible: true },
            {
                table: "ticket_activities",
                data: "seconds_active",
                required: false,
                visible: true,
            },
            {
                table: "ticket_activities",
                data: "ticket_activity_type_id",
                required: false,
                visible: true,
            },
            {
                table: "ticket_activities",
                data: "incoming_channel",
                required: false,
                visible: true,
            },
            {
                table: "ticket_activities",
                data: "outgoing_channel",
                required: false,
                visible: true,
            },
            { table: "ticket_activities", data: "notes", required: false, visible: true },
            {
                table: "ticket_activities",
                data: "recipient_ids",
                required: false,
                visible: false,
            },
        ]);
    const [contractCampaigns, setContractCampaigns] = useState([]);
    const [campaignsLoading, setCampaignsLoading] = useState(true);
    const [chosenContractCampaign, setChosenContractCampaign] = useState((_p = campaign === null || campaign === void 0 ? void 0 : campaign.contract_campaign_id) !== null && _p !== void 0 ? _p : "");
    const [statusTypes] = useState(["opened", "pending", "resolved"]);
    const [add, setAdd] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState((_q = campaign === null || campaign === void 0 ? void 0 : campaign.groups) !== null && _q !== void 0 ? _q : []);
    const [selectedActivityGroups, setSelectedActivityGroups] = useState((_r = campaign === null || campaign === void 0 ? void 0 : campaign.activity_groups) !== null && _r !== void 0 ? _r : []);
    const [selectedDetailsGroups, setSelectedDetailsGroups] = useState((_s = campaign === null || campaign === void 0 ? void 0 : campaign.details_groups) !== null && _s !== void 0 ? _s : []);
    const [groups, setGroups] = useState([]);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [groupsLoading, setGroupsLoading] = useState(true);
    const [relatedGroups, setRelatedGroups] = useState([]);
    const [relatedActivityGroups, setRelatedActivityGroups] = useState([]);
    const [relatedDetailsGroups, setRelatedDetailsGroups] = useState([]);
    const [nameChanged, setNameChanged] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);
    const [slugError, setSlugError] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState(null);
    const createCampaign = () => {
        setIsSaving(true);
        setIsError(false);
        api.CREATE_campaign(name, type, statusOptions, priorityOptions, activityOptions, active, language, description, queues, welcome, chosenContractCampaign, selectedGroups && selectedGroups.length > 0
            ? selectedGroups.map(group => group.id)
            : [], fieldOptions, customFieldOptions, slug === undefined && status === "new" ? name.split(" ").join("-").toLowerCase() : slug, farewellMessage, allowMultipleSubmits, requireCaptcha, selectedActivityGroups && selectedActivityGroups.length > 0
            ? selectedActivityGroups.map(group => group.id)
            : [], selectedDetailsGroups && selectedDetailsGroups.length > 0
            ? selectedDetailsGroups.map(group => group.id)
            : [])
            .then(response => {
            tenancy.setRefreshCampaigns(true);
            alert.show(t("campaigns.campaign-form.created"), "success");
            navigate(tenancy.link("/campaigns/" + response.data.campaign.id));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            alert.show(t("campaigns.campaign-form.creating-error"), "error");
        });
    };
    const updateCampaign = () => {
        if (!campaign)
            return;
        setIsSaving(true);
        setIsError(false);
        api.UPDATE_campaign(campaign === null || campaign === void 0 ? void 0 : campaign.id, name, type, statusOptions, priorityOptions, activityOptions, active, language, description, queues, welcome, chosenContractCampaign, selectedGroups && selectedGroups.length > 0
            ? selectedGroups.map(group => group.id)
            : [], fieldOptions, customFieldOptions, slug, farewellMessage, allowMultipleSubmits, requireCaptcha, selectedActivityGroups && selectedActivityGroups.length > 0
            ? selectedActivityGroups.map(group => group.id)
            : [], selectedDetailsGroups && selectedDetailsGroups.length > 0
            ? selectedDetailsGroups.map(group => group.id)
            : [])
            .then(() => {
            if (nameChanged && !tenancy.refreshCampaigns)
                tenancy.setRefreshCampaigns(true);
            alert.show(t("campaigns.campaign-form.updated"), "success");
            navigate(tenancy.link("/campaigns/" + (campaign === null || campaign === void 0 ? void 0 : campaign.id)));
        })
            .catch(error => {
            setIsSaving(false);
            setIsError(true);
            setErrors(error.response.data.errors);
            console.error(error.response);
            if (error.response.status === 409) {
                alert.show(error.response.data.message, "error");
            }
            else {
                alert.show(t("campaigns.campaign-form.updating-error"), "error");
            }
        });
    };
    const updateStatusOptions = (index, newName, newType) => {
        // @ts-ignore
        const updatedArray = [...statusOptions];
        if (newName !== null)
            updatedArray[index].name = newName;
        if (newType !== null)
            updatedArray[index].type = newType;
        setStatusOptions(updatedArray);
    };
    const updatePriorityOptions = (index, newName, newPrioNumber) => {
        // @ts-ignore
        const updatedArray = [...priorityOptions];
        if (newName !== null)
            updatedArray[index].name = newName;
        if (newPrioNumber !== null)
            updatedArray[index].level = newPrioNumber;
        setPriorityOptions(updatedArray);
    };
    const updateActivityOptions = (index, newName) => {
        // @ts-ignore
        const updatedArray = [...activityOptions];
        if (newName !== null)
            updatedArray[index].name = newName;
        setActivityOptions(updatedArray);
    };
    const updateFieldOptions = (updateVisible, updateRequired, index, type) => {
        let updatedArray;
        if (type === "custom_field") {
            // @ts-ignore
            updatedArray = [...customFieldOptions];
        }
        else {
            // @ts-ignore
            updatedArray = [...fieldOptions];
        }
        if (updateVisible === true)
            updatedArray[index].visible = !updatedArray[index].visible;
        if (updateRequired === true)
            updatedArray[index].required = !updatedArray[index].required;
        if (updateRequired === true && updatedArray[index].data === "phones_or_emails") {
            let phonesIndex = updatedArray.findIndex(a => a.data === "phones");
            let emailsIndex = updatedArray.findIndex(a => a.data === "emails");
            updatedArray[phonesIndex].visible = true;
            updatedArray[phonesIndex].required = false;
            updatedArray[emailsIndex].visible = true;
            updatedArray[emailsIndex].required = false;
        }
        if (updatedArray[index].visible === false)
            updatedArray[index].required = false;
        if (type === "custom_field")
            setCustomFieldOptions(updatedArray);
        else
            setFieldOptions(updatedArray);
    };
    const removeActivityOption = index => {
        const updatedArray = [];
        let i = 0;
        for (let option of [...activityOptions]) {
            if (option !== [...activityOptions][index]) {
                i++;
                option.index = i;
                updatedArray.push(option);
            }
        }
        setActivityOptions(updatedArray);
        setIsError(false);
    };
    const removeStatusOption = index => {
        const updatedArray = [];
        let i = 0;
        for (let option of [...statusOptions]) {
            if (option !== [...statusOptions][index]) {
                i++;
                option.index = i;
                updatedArray.push(option);
            }
        }
        setStatusOptions(updatedArray);
        setIsError(false);
    };
    const removePriorityOption = index => {
        const updatedArray = [];
        let i = 0;
        for (let option of [...priorityOptions]) {
            if (option !== [...priorityOptions][index]) {
                i++;
                option.index = i;
                updatedArray.push(option);
            }
        }
        setPriorityOptions(updatedArray);
        setIsError(false);
    };
    const validateSlug = (slug) => {
        if (slug.startsWith("-") || slug.endsWith("-")) {
            setSlugError(true);
        }
        else {
            setSlugError(false);
        }
    };
    useEffect(() => {
        if (add === "status") {
            statusOptions.push({
                id: "-1",
                name: "",
                type: "opened",
                index: statusOptions.length,
            });
            setAdd(null);
        }
        if (add === "priority") {
            priorityOptions.push({
                id: "-1",
                name: "",
                level: 1,
                index: statusOptions.length,
            });
            setAdd(null);
        }
        if (add === "activity") {
            activityOptions.push({
                name: "",
                index: statusOptions.length,
            });
            setAdd(null);
        }
    }, [add]);
    useEffect(() => {
        if (status === "new" && welcome === "") {
            if (type === "survey") {
                setWelcome('<p style="text-align: center;">Please take a moment to complete this short survey.</p><p style="text-align: center;">Your feedback will help us to continually improve your experience!</p>');
            }
        }
    }, [type]);
    useEffect(() => {
        if (status === "new" || customFieldOptions.length === 0) {
            setIsLoadingCustomFields(true);
            let fields = [];
            api.GET_customFields("contacts", 10000, 1, "index")
                .then(response => {
                response.data.custom_fields.map(field => fields.push({
                    visible: false,
                    required: false,
                    custom_field: field,
                    custom_field_id: field.id,
                }));
                api.GET_customFields("ticket_activities", 10000, 1, "index")
                    .then(response => {
                    response.data.custom_fields.map(field => fields.push({
                        visible: false,
                        required: false,
                        custom_field: field,
                        custom_field_id: field.id,
                    }));
                    setIsLoadingCustomFields(false);
                    setCustomFieldOptions(fields);
                })
                    .catch(error => {
                    if (!api.isCancel(error)) {
                        console.error(error);
                        setIsLoadingCustomFields(false);
                    }
                });
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsLoadingCustomFields(false);
                }
            });
        }
        api.GET_campaigns(10000, 1, ["contract"], undefined, "name")
            .then(response => {
            const campaigns = response.data.campaigns;
            setContractCampaigns(campaigns.filter(campaign => campaign.active === true));
            setCampaignsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setCampaignsLoading(false);
            }
        });
        api.GET_groups()
            .then(response => {
            let groups = flatGroups(response.data.groups, 0, []);
            setGroups(groups);
            groups = sortGroups(groups, 0, [], 1);
            setSortedGroups(groups);
            setGroupsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setGroupsLoading(false);
            }
        });
        return () => api.cancel();
    }, []);
    useEffect(() => {
        if (status === "edit" && !nameChanged) {
            setNameChanged(true);
        }
    }, [name]);
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(DescriptionIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("details") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.campaign-details") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(InputText, { label: t("campaigns.campaign-attributes.title") + " *", required: true, value: name, setValue: setName, error: "name", errors: errors, isError: isError, setIsError: setIsError }) })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-attributes.active"), required: false, checked: active ? active : false, setChecked: () => setActive(!active), info: t("campaigns.campaign-form.active-helper-text") }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputTextArea, { label: t("campaigns.campaign-attributes.description"), required: false, value: description, rows: 4, setValue: setDescription, error: "description", errors: errors, isError: isError, setIsError: setIsError }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, error: isError ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("type") : false }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-attributes.type") + " *" }), _jsx(Select, Object.assign({ label: t("campaigns.campaign-attributes.type") + " *", value: type, onChange: event => {
                                                                    setType(event.target.value);
                                                                    if (event.target.value === "contract") {
                                                                        setChosenContractCampaign("");
                                                                    }
                                                                    if (isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("type")))
                                                                        setIsError(false);
                                                                } }, { children: selectTypes.map(selectType => {
                                                                    return (_jsx(MenuItem, Object.assign({ value: selectType }, { children: selectType === "inbound"
                                                                            ? t("campaigns.campaign-attributes.types.inbound")
                                                                            : selectType === "outbound"
                                                                                ? t("campaigns.campaign-attributes.types.outbound")
                                                                                : selectType === "survey"
                                                                                    ? t("campaigns.campaign-attributes.types.survey")
                                                                                    : selectType === "contract"
                                                                                        ? t("campaigns.campaign-attributes.types.contract")
                                                                                        : "campaigns.campaign-attributes.types.all" }), selectType));
                                                                }) }))] })), isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("type")) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["type"][0] }))) : (_jsx(FormHelperText, { children: type === "inbound"
                                                            ? t("campaigns.campaign-form.type-helper-text.inbound")
                                                            : type === "outbound"
                                                                ? t("campaigns.campaign-form.type-helper-text.outbound")
                                                                : type === "survey"
                                                                    ? t("campaigns.campaign-form.type-helper-text.survey")
                                                                    : type === "contract"
                                                                        ? t("campaigns.campaign-form.type-helper-text.contract")
                                                                        : "" }))] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Autocomplete, { multiple: true, options: [], freeSolo: true, renderTags: (value, getTagProps) => value.map((option, index) => (_jsx(Chip, Object.assign({ variant: "filled", label: option }, getTagProps({ index }))))), value: queues, onChange: (event, value) => {
                                                            setQueues(value);
                                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("campaigns.campaign-attributes.3cx-queues") }))) }), _jsx(Box, Object.assign({ sx: { wordBreak: "break-all" } }, { children: isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("queues")) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["queues"][0] }))) : (_jsx(FormHelperText, { children: window.location.protocol +
                                                                "//" +
                                                                window.location.host +
                                                                "/3cx/%CallerDisplayName%/%CallerNumber%" })) }))] })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true, variant: "outlined" }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-attributes.language") + " *" }), _jsxs(Select, Object.assign({ label: t("campaigns.campaign-attributes.language") +
                                                                " *", value: language, onChange: event => {
                                                                setLanguage(event.target.value);
                                                            } }, { children: [_jsx(MenuItem, Object.assign({ value: "en" }, { children: t("campaigns.campaign-form.english") })), _jsx(MenuItem, Object.assign({ value: "hr" }, { children: t("campaigns.campaign-form.croatian") }))] }))] })) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Autocomplete, { disabled: type === "contract", options: contractCampaigns, getOptionLabel: campaign => campaign.name, isOptionEqualToValue: (option, value) => option.id === value.id, value: contractCampaigns.find(campaign => campaign.id === chosenContractCampaign)
                                                            ? contractCampaigns.find(campaign => campaign.id === chosenContractCampaign)
                                                            : null, onChange: (event, value) => setChosenContractCampaign(value ? value.id : ""), loading: campaignsLoading, renderOption: (props, campaign) => (_jsx("li", Object.assign({}, props, { children: _jsx(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                } }, { children: _jsx(Box, Object.assign({ sx: {
                                                                        display: "flex",
                                                                    } }, { children: _jsx(Typography, { children: campaign.name }) })) }), campaign.id) }))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("campaigns.campaign-attributes.types.contract"), fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [campaignsLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }), _jsx(FormHelperText, { children: t("campaigns.campaign-form.contract-message") })] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: [_jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { multiple: true, options: sortedGroups.map(sort => sort.group), getOptionLabel: group => {
                                                                var _a;
                                                                return sortedGroups.find(sort => sort.group.id === group.id)
                                                                    ? ((_a = sortedGroups.find(sort => sort.group.id === group.id)) === null || _a === void 0 ? void 0 : _a.id) +
                                                                        ". " +
                                                                        group.name
                                                                    : group.name;
                                                            }, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => !!relatedGroups.find(group => group.id === option.id), value: selectedGroups, loading: groupsLoading, disableCloseOnSelect: true, onChange: (event, value) => {
                                                                let disabledGroups = [];
                                                                value.map(group => {
                                                                    disabledGroups = relatedChildGroups(group, disabledGroups);
                                                                    disabledGroups = relatedParentGroups(group, groups, disabledGroups);
                                                                });
                                                                setRelatedGroups(disabledGroups);
                                                                setSelectedGroups(value);
                                                            }, onOpen: () => {
                                                                let disabledGroups = [];
                                                                selectedGroups &&
                                                                    selectedGroups.map(group => {
                                                                        let chosen = groupInGroups(group, groups);
                                                                        if (chosen) {
                                                                            disabledGroups = relatedChildGroups(chosen, disabledGroups);
                                                                            disabledGroups =
                                                                                relatedParentGroups(chosen, groups, disabledGroups);
                                                                        }
                                                                    });
                                                                setRelatedGroups(disabledGroups);
                                                            }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("groups.campaign-groups") }))) }) })), _jsx(FormHelperText, { children: t("campaigns.campaign-form.groups-helper-text") })] })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { multiple: true, options: sortedGroups.map(sort => sort.group), getOptionLabel: group => {
                                                            var _a;
                                                            return sortedGroups.find(sort => sort.group.id === group.id)
                                                                ? ((_a = sortedGroups.find(sort => sort.group.id === group.id)) === null || _a === void 0 ? void 0 : _a.id) +
                                                                    ". " +
                                                                    group.name
                                                                : group.name;
                                                        }, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => !!relatedDetailsGroups.find(group => group.id === option.id), value: selectedDetailsGroups, loading: groupsLoading, disableCloseOnSelect: true, onChange: (event, value) => {
                                                            let disabledGroups = [];
                                                            value.map(group => {
                                                                disabledGroups = relatedChildGroups(group, disabledGroups);
                                                                disabledGroups = relatedParentGroups(group, groups, disabledGroups);
                                                            });
                                                            setRelatedDetailsGroups(disabledGroups);
                                                            setSelectedDetailsGroups(value);
                                                        }, onOpen: () => {
                                                            let disabledGroups = [];
                                                            selectedDetailsGroups &&
                                                                selectedDetailsGroups.map(group => {
                                                                    let chosen = groupInGroups(group, groups);
                                                                    if (chosen) {
                                                                        disabledGroups = relatedChildGroups(chosen, disabledGroups);
                                                                        disabledGroups =
                                                                            relatedParentGroups(chosen, groups, disabledGroups);
                                                                    }
                                                                });
                                                            setRelatedDetailsGroups(disabledGroups);
                                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("groups.details-groups") }))) }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 12 }, { children: _jsx(FormControl, Object.assign({ fullWidth: true }, { children: _jsx(Autocomplete, { multiple: true, options: sortedGroups.map(sort => sort.group), getOptionLabel: group => {
                                                            var _a;
                                                            return sortedGroups.find(sort => sort.group.id === group.id)
                                                                ? ((_a = sortedGroups.find(sort => sort.group.id === group.id)) === null || _a === void 0 ? void 0 : _a.id) +
                                                                    ". " +
                                                                    group.name
                                                                : group.name;
                                                        }, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => !!relatedActivityGroups.find(group => group.id === option.id), value: selectedActivityGroups, loading: groupsLoading, disableCloseOnSelect: true, onChange: (event, value) => {
                                                            let disabledGroups = [];
                                                            value.map(group => {
                                                                disabledGroups = relatedChildGroups(group, disabledGroups);
                                                                disabledGroups = relatedParentGroups(group, groups, disabledGroups);
                                                            });
                                                            setRelatedActivityGroups(disabledGroups);
                                                            setSelectedActivityGroups(value);
                                                        }, onOpen: () => {
                                                            let disabledGroups = [];
                                                            selectedActivityGroups &&
                                                                selectedActivityGroups.map(group => {
                                                                    let chosen = groupInGroups(group, groups);
                                                                    if (chosen) {
                                                                        disabledGroups = relatedChildGroups(chosen, disabledGroups);
                                                                        disabledGroups =
                                                                            relatedParentGroups(chosen, groups, disabledGroups);
                                                                    }
                                                                });
                                                            setRelatedActivityGroups(disabledGroups);
                                                        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("groups.activity-groups") }))) }) })) })), type === "survey" && (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsxs(Grid, Object.assign({ item: true, xs: 6 }, { children: [_jsx(InputSwitch, { label: t("campaigns.campaign-attributes.allow-multiple-submissions"), required: false, checked: allowMultipleSubmits
                                                                    ? allowMultipleSubmits
                                                                    : false, setChecked: () => setAllowMultipleSubmits(!allowMultipleSubmits) }), _jsx(FormHelperText, { children: t("campaigns.campaign-form.multiple-submissions-helper-text") })] })), process.env.GOOGLE_RECAPTCHA_KEY && (_jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(InputSwitch, { label: "Google reCAPTCHA", required: false, checked: requireCaptcha ? requireCaptcha : false, setChecked: () => setRequireCaptcha(!requireCaptcha) }) }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 12 }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, variant: "outlined", error: isError
                                                                    ? errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("slug")
                                                                    : slugError }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-attributes.slug") +
                                                                            " *" }), _jsx(OutlinedInput, { label: t("campaigns.campaign-attributes.slug") + " *", startAdornment: _jsx(InputAdornment, Object.assign({ position: "start" }, { children: window.location.protocol +
                                                                                "//" +
                                                                                ((_t = tenancy.client) === null || _t === void 0 ? void 0 : _t.domain) +
                                                                                "." +
                                                                                window.location.host +
                                                                                "/" +
                                                                                (language === "hr"
                                                                                    ? "anketa"
                                                                                    : "survey") +
                                                                                "/" })), value: slug === undefined && status === "new"
                                                                            ? name
                                                                                .split(" ")
                                                                                .join("-")
                                                                                .toLowerCase()
                                                                            : slug, onChange: event => {
                                                                            validateSlug(event.target.value);
                                                                            setSlug(event.target.value
                                                                                ? cleanSlug(event.target.value)
                                                                                : "");
                                                                            if (isError)
                                                                                setIsError(false);
                                                                        } })] })), slugError ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: t("campaigns.campaign-form.slug-error-message") }))) : isError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("slug")) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["slug"][0] }))) : ("")] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputEditor, { label: t("campaigns.campaign-attributes.instructions"), required: false, value: welcome, setValue: setWelcome, error: "instructions", errors: errors, isError: isError, setIsError: setIsError }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(InputEditor, { label: t("campaigns.campaign-attributes.farewell") + " *", required: true, value: farewellMessage, setValue: setFarewellMessage, error: "farewell_message", errors: errors, isError: isError, setIsError: setIsError }) }))] }))] })) })) }) }))] })) })), _jsx(Box, Object.assign({ sx: {
                    paddingTop: 2,
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(StatusIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("campaigns.campaign-form.statuses") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.campaign-form.campaign-statuses") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [statusOptions === null || statusOptions === void 0 ? void 0 : statusOptions.map((option, index) => {
                                                return (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Badge, Object.assign({ sx: {
                                                                    width: "100%",
                                                                    color: "rgb(244, 67, 54)",
                                                                }, badgeContent: _jsx(Box, Object.assign({ sx: {
                                                                        paddingBottom: 0.5,
                                                                    } }, { children: _jsx(Tooltip
                                                                    // @ts-ignore
                                                                    , Object.assign({ 
                                                                        // @ts-ignore
                                                                        title: t("campaigns.campaign-form.remove-status-option"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ style: {
                                                                                color: "rgb(244, 67, 54)",
                                                                            }, onClick: () => removeStatusOption(index), size: "large" }, { children: _jsx(RemoveCircleIcon, {}) })) })) })) }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-form.option") +
                                                                                    " " +
                                                                                    (index + 1) +
                                                                                    " *" }), _jsx(OutlinedInput, { label: t("campaigns.campaign-form.option") +
                                                                                    " " +
                                                                                    (index + 1) +
                                                                                    " *", required: true, value: statusOptions[index].name, onChange: event => {
                                                                                    updateStatusOptions(index, event.target.value, null);
                                                                                    if (isError &&
                                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("ticket_statuses." +
                                                                                            index +
                                                                                            ".name")))
                                                                                        setIsError(false);
                                                                                } })] })), isError &&
                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("ticket_statuses." + index + ".name")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["ticket_statuses." +
                                                                            index +
                                                                            ".name"][0] })))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-attributes.type") + " *" }), _jsx(Select, Object.assign({ label: t("campaigns.campaign-attributes.type") + " *", value: statusOptions[index].type, onChange: event => {
                                                                            updateStatusOptions(index, null, event.target.value);
                                                                        } }, { children: statusTypes.map(statusType => {
                                                                            return (_jsx(MenuItem, Object.assign({ value: statusType }, { children: statusType === "opened"
                                                                                    ? t("campaigns.campaign-attributes.status-types.opened")
                                                                                    : statusType ===
                                                                                        "pending"
                                                                                        ? t("campaigns.campaign-attributes.status-types.pending")
                                                                                        : t("campaigns.campaign-attributes.status-types.resolved") }), statusType));
                                                                        }) }))] })) }))] }, index));
                                            }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Tooltip
                                                // @ts-ignore
                                                , Object.assign({ 
                                                    // @ts-ignore
                                                    title: t("campaigns.campaign-form.add-new-status-option"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ sx: { padding: 0 }, edge: "end", onClick: () => {
                                                            setAdd("status");
                                                        }, size: "large" }, { children: _jsx(AddBoxIcon, { fontSize: "large" }) })) })) }))] })) })) }) }))] })) })), _jsx(Box, Object.assign({ sx: {
                    paddingTop: 2,
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(PriorityIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("campaigns.campaign-form.priorities") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.campaign-form.campaign-priorities") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [priorityOptions === null || priorityOptions === void 0 ? void 0 : priorityOptions.map((option, index) => {
                                                return (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Badge, Object.assign({ sx: {
                                                                    width: "100%",
                                                                    color: "rgb(244, 67, 54)",
                                                                }, badgeContent: _jsx(Box, Object.assign({ sx: {
                                                                        paddingBottom: 0.5,
                                                                    } }, { children: _jsx(Tooltip
                                                                    // @ts-ignore
                                                                    , Object.assign({ 
                                                                        // @ts-ignore
                                                                        title: t("campaigns.campaign-form.remove-priority-option"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ style: {
                                                                                color: "rgb(244, 67, 54)",
                                                                            }, onClick: () => removePriorityOption(index), size: "large" }, { children: _jsx(RemoveCircleIcon, {}) })) })) })) }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-form.option") +
                                                                                    " " +
                                                                                    (index + 1) +
                                                                                    " *" }), _jsx(OutlinedInput, { label: t("campaigns.campaign-form.option") +
                                                                                    " " +
                                                                                    (index + 1) +
                                                                                    " *", required: true, value: priorityOptions[index].name, onChange: event => {
                                                                                    updatePriorityOptions(index, event.target.value, null);
                                                                                    if (isError &&
                                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("ticket_priorities." +
                                                                                            index +
                                                                                            ".name")))
                                                                                        setIsError(false);
                                                                                } })] })), isError &&
                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("ticket_priorities." +
                                                                            index +
                                                                            ".name")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["ticket_priorities." +
                                                                            index +
                                                                            ".name"][0] })))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(Box, Object.assign({ sx: { paddingTop: 2 } }, { children: _jsx(InputSlider, { required: true, marks: [
                                                                        {
                                                                            value: 1,
                                                                            label: t("campaigns.campaign-attributes.priority-types.high"),
                                                                        },
                                                                        {
                                                                            value: 50,
                                                                            label: t("campaigns.campaign-attributes.priority-types.medium"),
                                                                        },
                                                                        {
                                                                            value: 100,
                                                                            label: t("campaigns.campaign-attributes.priority-types.low"),
                                                                        },
                                                                    ], value: priorityOptions[index].level, setValue: event => updatePriorityOptions(index, null, event), min: 1, max: 100, step: 1 }) })) }))] }, index));
                                            }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Tooltip
                                                // @ts-ignore
                                                , Object.assign({ 
                                                    // @ts-ignore
                                                    title: t("campaigns.campaign-form.add-new-priority-option"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ sx: { padding: 0 }, edge: "end", onClick: () => {
                                                            setAdd("priority");
                                                        }, size: "large" }, { children: _jsx(AddBoxIcon, { fontSize: "large" }) })) })) }))] })) })) }) }))] })) })), _jsx(Box, Object.assign({ sx: {
                    paddingTop: 2,
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(ActivityIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("campaigns.campaign-form.activities") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.campaign-form.campaign-activities") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [activityOptions === null || activityOptions === void 0 ? void 0 : activityOptions.map((option, index) => {
                                                return (_jsxs(Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Badge, Object.assign({ sx: {
                                                                    width: "100%",
                                                                    color: "rgb(244, 67, 54)",
                                                                }, badgeContent: _jsx(Box, Object.assign({ sx: {
                                                                        paddingBottom: 0.5,
                                                                    } }, { children: _jsx(Tooltip
                                                                    // @ts-ignore
                                                                    , Object.assign({ 
                                                                        // @ts-ignore
                                                                        title: t("campaigns.campaign-form.remove-activity-option"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ style: {
                                                                                color: "rgb(244, 67, 54)",
                                                                            }, onClick: () => removeActivityOption(index), size: "large" }, { children: _jsx(RemoveCircleIcon, {}) })) })) })) }, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: t("campaigns.campaign-form.option") +
                                                                                    " " +
                                                                                    (index + 1) +
                                                                                    " *" }), _jsx(OutlinedInput, { label: t("campaigns.campaign-form.option") +
                                                                                    " " +
                                                                                    (index + 1) +
                                                                                    " *", required: true, value: activityOptions[index].name, onChange: event => {
                                                                                    updateActivityOptions(index, event.target.value);
                                                                                    if (isError &&
                                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("ticket_activities." +
                                                                                            index +
                                                                                            ".name")))
                                                                                        setIsError(false);
                                                                                } })] })), isError &&
                                                                        (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty("ticket_activities." +
                                                                            index +
                                                                            ".name")) && (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors["ticket_activities." +
                                                                            index +
                                                                            ".name"][0] })))] })) })), _jsx(Grid, { item: true, xs: 12, sm: 6 })] }, index));
                                            }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Tooltip
                                                // @ts-ignore
                                                , Object.assign({ 
                                                    // @ts-ignore
                                                    title: t("campaigns.campaign-form.add-new-activity-option"), placement: "top", arrow: true }, { children: _jsx(IconButton, Object.assign({ sx: { padding: 0 }, edge: "end", onClick: () => {
                                                            setAdd("activity");
                                                        }, size: "large" }, { children: _jsx(AddBoxIcon, { fontSize: "large" }) })) })) }))] })) })) }) }))] })) })), _jsx(Box, Object.assign({ sx: {
                    paddingTop: 2,
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    position: "sticky",
                                    top: "100px",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(InputIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("options.options") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("campaigns.campaign-form.field-options.field-options") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Paper, { children: _jsx(Box, Object.assign({ sx: {
                                        padding: 4,
                                    } }, { children: isLoadingCustomFields ? (_jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "30px",
                                        } }, { children: _jsx(CircularProgress, { color: "inherit" }) }))) : (_jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign-form.field-options.contact-fields") })), _jsx(FormHelperText, { children: t("campaigns.campaign-form.fields-helper-text") })] })), (_u = fieldOptions
                                                .filter(o => o.table === "contacts")) === null || _u === void 0 ? void 0 : _u.map((fieldOption, index) => {
                                                return (_jsx(Fragment, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, { children: fieldOption.data ===
                                                                        "first_name"
                                                                        ? t("contacts.contact-attributes.first-name")
                                                                        : fieldOption.data ===
                                                                            "last_name"
                                                                            ? t("contacts.contact-attributes.last-name")
                                                                            : fieldOption.data ===
                                                                                "type"
                                                                                ? t("contacts.contact-attributes.type.type")
                                                                                : fieldOption.data ===
                                                                                    "company"
                                                                                    ? t("contacts.contact-attributes.company")
                                                                                    : fieldOption.data === "oib"
                                                                                        ? "OIB"
                                                                                        : fieldOption.data ===
                                                                                            "phones"
                                                                                            ? t("contacts.contact-attributes.phones")
                                                                                            : fieldOption.data ===
                                                                                                "emails"
                                                                                                ? t("contacts.contact-attributes.emails")
                                                                                                : fieldOption.data ===
                                                                                                    "address"
                                                                                                    ? t("contacts.contact-attributes.address")
                                                                                                    : t("campaigns.campaign-form.field-options.phones-or-emails") }) })), _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [fieldOption.data !==
                                                                        "phones_or_emails" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.visible"), disabled: (fieldOption.data ===
                                                                                "phones" ||
                                                                                fieldOption.data ===
                                                                                    "emails") &&
                                                                                fieldOptions.find(option => option.data ===
                                                                                    "phones_or_emails") &&
                                                                                fieldOptions.find(option => option.data ===
                                                                                    "phones_or_emails").required, required: false, checked: fieldOptions.find(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table)
                                                                                ? fieldOptions.find(option => option.data ===
                                                                                    fieldOption.data &&
                                                                                    option.table ===
                                                                                        fieldOption.table).visible
                                                                                : true, setChecked: () => updateFieldOptions(true, false, fieldOptions.findIndex(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table), "field") }) }))), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.required"), required: false, disabled: !fieldOptions.find(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table).visible ||
                                                                                ((fieldOption.data ===
                                                                                    "phones" ||
                                                                                    fieldOption.data ===
                                                                                        "emails") &&
                                                                                    fieldOptions.find(option => option.data ===
                                                                                        "phones_or_emails") &&
                                                                                    fieldOptions.find(option => option.data ===
                                                                                        "phones_or_emails").required), checked: fieldOptions.find(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table)
                                                                                ? fieldOptions.find(option => option.data ===
                                                                                    fieldOption.data &&
                                                                                    option.table ===
                                                                                        fieldOption.table).required
                                                                                : false, setChecked: () => updateFieldOptions(false, true, fieldOptions.findIndex(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table), "field") }) }))] }))] })) }, index));
                                            }), customFieldOptions === null || customFieldOptions === void 0 ? void 0 : customFieldOptions.filter(fieldOption => fieldOption.custom_field.table_name ===
                                                "contacts").map((fieldOption, index) => {
                                                return (_jsx(Fragment, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, { children: fieldOption.custom_field
                                                                        .field_name }) })), _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.visible"), required: false, checked: customFieldOptions.find(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id)
                                                                                ? customFieldOptions.find(option => option.custom_field_id ===
                                                                                    fieldOption.custom_field_id).visible
                                                                                : true, setChecked: () => updateFieldOptions(true, false, customFieldOptions.findIndex(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id), "custom_field") }) })), fieldOption.custom_field
                                                                        .field_type !== "switch" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.required"), required: false, disabled: !customFieldOptions.find(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id).visible, checked: customFieldOptions.find(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id)
                                                                                ? customFieldOptions.find(option => option.custom_field_id ===
                                                                                    fieldOption.custom_field_id).required
                                                                                : false, setChecked: () => updateFieldOptions(false, true, customFieldOptions.findIndex(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id), "custom_field") }) })))] }))] })) }, index));
                                            }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign-form.field-options.ticket-details-fields") })), type === "survey" && (_jsx(FormHelperText, { children: t("campaigns.campaign-form.detail-fields-helper-text") }))] })), (_v = fieldOptions
                                                .filter(o => o.table === "ticket_details")) === null || _v === void 0 ? void 0 : _v.map((fieldOption, index) => {
                                                return (_jsx(Fragment, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, { children: fieldOption.data === "estimate"
                                                                        ? t("tickets.ticket-attributes.estimated-time")
                                                                        : fieldOption.data ===
                                                                            "progress"
                                                                            ? t("tickets.ticket-attributes.progress")
                                                                            : fieldOption.data ===
                                                                                "start_date_time"
                                                                                ? t("tickets.ticket-attributes.start-date")
                                                                                : fieldOption.data ===
                                                                                    "due_date_time"
                                                                                    ? t("tickets.ticket-attributes.due-date")
                                                                                    : fieldOption.data ===
                                                                                        "notes"
                                                                                        ? t("tickets.ticket-attributes.notes")
                                                                                        : fieldOption.data ===
                                                                                            "forwarded_call"
                                                                                            ? t("tickets.ticket-attributes.forwarded-call")
                                                                                            : t("tickets.ticket-attributes.assignee") }) })), _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.visible"), required: true, checked: fieldOptions.find(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table)
                                                                                ? fieldOptions.find(option => option.data ===
                                                                                    fieldOption.data &&
                                                                                    option.table ===
                                                                                        fieldOption.table).visible
                                                                                : true, setChecked: () => updateFieldOptions(true, false, fieldOptions.findIndex(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table), "field") }) })), fieldOption.data !=
                                                                        "forwarded_call" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.required"), required: false, disabled: !fieldOptions.find(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table).visible, checked: fieldOptions.find(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table)
                                                                                ? fieldOptions.find(option => option.data ===
                                                                                    fieldOption.data &&
                                                                                    option.table ===
                                                                                        fieldOption.table).required
                                                                                : false, setChecked: () => updateFieldOptions(false, true, fieldOptions.findIndex(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table), "field") }) })))] }))] })) }, index));
                                            }), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Divider, { light: true }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(Typography, Object.assign({ variant: "overline" }, { children: t("campaigns.campaign-form.field-options.ticket-activity-fields") })), type === "survey" && (_jsx(FormHelperText, { children: t("campaigns.campaign-form.activity-fields-helper-text") }))] })), (_w = fieldOptions
                                                .filter(o => o.table === "ticket_activities")) === null || _w === void 0 ? void 0 : _w.map((fieldOption, index) => {
                                                return (_jsx(Fragment, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, { children: fieldOption.data === "notes"
                                                                        ? t("tickets.ticket-activities.ticket-activity-form.message")
                                                                        : fieldOption.data ===
                                                                            "seconds_active"
                                                                            ? t("tickets.ticket-activities.ticket-activity-form.worked-time")
                                                                            : fieldOption.data ===
                                                                                "ticket_activity_type_id"
                                                                                ? t("tickets.ticket-activities.activity")
                                                                                : fieldOption.data ===
                                                                                    "incoming_channel"
                                                                                    ? t("tickets.ticket-activities.ticket-activity-form.incoming-channel")
                                                                                    : fieldOption.data ===
                                                                                        "recipient_ids"
                                                                                        ? t("tickets.activity.recipients")
                                                                                        : t("tickets.ticket-activities.ticket-activity-form.outgoing-channel") }) })), _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.visible"), required: true, checked: fieldOptions.find(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table)
                                                                                ? fieldOptions.find(option => option.data ===
                                                                                    fieldOption.data &&
                                                                                    option.table ===
                                                                                        fieldOption.table).visible
                                                                                : true, setChecked: () => updateFieldOptions(true, false, fieldOptions.findIndex(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table), "field") }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.required"), required: false, disabled: !fieldOptions.find(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table).visible, checked: fieldOptions.find(option => option.data ===
                                                                                fieldOption.data)
                                                                                ? fieldOptions.find(option => option.data ===
                                                                                    fieldOption.data &&
                                                                                    option.table ===
                                                                                        fieldOption.table).required
                                                                                : false, setChecked: () => updateFieldOptions(false, true, fieldOptions.findIndex(option => option.data ===
                                                                                fieldOption.data &&
                                                                                option.table ===
                                                                                    fieldOption.table), "field") }) }))] }))] })) }, index));
                                            }), customFieldOptions === null || customFieldOptions === void 0 ? void 0 : customFieldOptions.filter(fieldOption => fieldOption.custom_field.table_name ===
                                                "ticket_activities").map((fieldOption, index) => {
                                                return (_jsx(Fragment, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, { children: fieldOption.custom_field
                                                                        .field_name }) })), _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.visible"), required: false, checked: customFieldOptions.find(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id)
                                                                                ? customFieldOptions.find(option => option.custom_field_id ===
                                                                                    fieldOption.custom_field_id).visible
                                                                                : true, setChecked: () => updateFieldOptions(true, false, customFieldOptions.findIndex(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id), "custom_field") }) })), fieldOption.custom_field
                                                                        .field_type !== "switch" && (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsx(InputSwitch, { label: t("campaigns.campaign-form.field-options.required"), required: false, disabled: !customFieldOptions.find(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id).visible, checked: customFieldOptions.find(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id)
                                                                                ? customFieldOptions.find(option => option.custom_field_id ===
                                                                                    fieldOption.custom_field_id).required
                                                                                : false, setChecked: () => updateFieldOptions(false, true, customFieldOptions.findIndex(option => option.custom_field_id ===
                                                                                fieldOption.custom_field_id), "custom_field") }) })))] }))] })) }, index));
                                            })] }))) })) }) }))] })) })), _jsx(Box, Object.assign({ sx: {
                    paddingTop: 2,
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Hidden, Object.assign({ mdDown: true }, { children: _jsx(Grid, { item: true, xs: 12, md: 4 }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "flex-end",
                                } }, { children: _jsx(LoadingButton, Object.assign({ type: "button", variant: "contained", color: "secondary", loading: isSaving, disabled: !name ||
                                        !type ||
                                        statusOptions.length === 0 ||
                                        priorityOptions.length === 0 ||
                                        activityOptions.length === 0 ||
                                        slugError, onClick: () => status === "new" ? createCampaign() : updateCampaign(), loadingIndicator: _jsx(CircularProgress, { color: "inherit", size: 16 }) }, { children: status === "new"
                                        ? t("form-component.create")
                                        : t("form-component.update") })) })) }))] })) }))] }));
}
