import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import ClientsIcon from "@mui/icons-material/Business";
import RefreshIcon from "@mui/icons-material/Refresh";
import NewIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Settings";
import Table from "../blocks/table";
import { useAlert } from "../../../services/alert";
import { useAuth } from "../../../services/auth";
import { cleanText } from "../../../helpers/clean";
import { useTenancy } from "../../../services/tenancy";
import { useApiCentral } from "../../../api/api-central";
export default function UserClients({ user }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const auth = useAuth();
    const tenancy = useTenancy();
    const api = useApiCentral();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [isEditLoading2, setIsEditLoading2] = useState(false);
    const [isDeleteOpened, setIsDeleteOpened] = useState(false);
    const [allClients, setAllClients] = useState([]);
    const [allUserClients, setAllUserClients] = useState([]);
    const [client, setClient] = useState(null);
    const [searchClients, setSearchClients] = useState("");
    const searchClientsRef = useRef(searchClients);
    const [role, setRole] = useState("agent");
    const [isManage, setIsManage] = useState(false);
    const [isEditOpened, setIsEditOpened] = useState(false);
    const [tableQuery, setTableQuery] = useState();
    const isMobile = useMediaQuery("(max-width: 767px)");
    useEffect(() => {
        if (!isEditOpened)
            return;
        setIsEditLoading2(true);
        api.GET_userClients(user.id, 10000, 1)
            .then(response => {
            setAllUserClients(response.data.tenants);
            setIsEditLoading2(false);
        })
            .catch(error => {
            if (!api.isCancel(error))
                console.error(error);
        });
        return () => api.cancel();
    }, [isEditOpened]);
    useEffect(() => {
        setAllClients([]);
        if (searchClients === "")
            return;
        setIsEditLoading(true);
        debounceSearchClients(cleanText(searchClientsRef.current));
    }, [searchClients]);
    const debounceSearchClients = useCallback(debounce(search => {
        getClients(search);
    }, 700), []);
    const getClients = search => {
        if (searchClientsRef.current !== "") {
            api.GET_clients(10000, 1, undefined, undefined, search)
                .then(response => {
                setAllClients(response.data.tenants);
                setIsEditLoading(false);
            })
                .catch(error => {
                if (!api.isCancel(error)) {
                    console.error(error);
                    setIsEditLoading(false);
                }
            });
        }
        else {
            setIsEditLoading(false);
        }
    };
    const refreshUserClients = () => {
        var _a;
        // @ts-ignore
        (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.onQueryChange();
    };
    const getUserClients = query => {
        setTableQuery(query);
        return new Promise(resolve => {
            setIsLoading(true);
            api.GET_userClients(user.id, query.pageSize, query.page + 1, query.orderBy ? query.orderBy.field : null, query.orderDirection ? query.orderDirection : null, query.search ? query.search : null)
                .then(response => {
                resolve({
                    data: response.data.tenants,
                    page: response.data.meta.current_page - 1,
                    totalCount: response.data.meta.total,
                });
            })
                .catch(error => {
                console.error(error);
            })
                .finally(() => {
                setIsLoading(false);
            });
        });
    };
    const newUserClient = () => {
        if (!client)
            return;
        setIsLoading(true);
        api.CREATE_userOnClient(client.id, user.id, role)
            .then(() => {
            setIsLoading(false);
            refreshUserClients();
            alert.show(t("users.user-clients.user-added"), "success");
        })
            .catch(error => {
            setIsLoading(false);
            console.error(error.data);
            alert.show(t("users.user-clients.adding-error-occured"), "error");
        })
            .finally(() => {
            setClient(null);
            setRole("agent");
        });
    };
    const updateClientUser = () => {
        if (!client)
            return;
        setIsLoading(true);
        api.UPDATE_user(user.id, user.first_name, user.last_name, user.email, user.username, user.is_enabled, role, undefined, undefined, undefined, undefined, [], user.global_id, client.id)
            .then(() => {
            setIsLoading(false);
            refreshUserClients();
            alert.show(t("clients.client-users.user-updated"), "success");
            setClient(null);
            setRole("agent");
            refreshCurrentClients(user);
        })
            .catch(error => {
            setIsLoading(false);
            console.error(error.data);
            alert.show(t("clients.client-users.updating-error-occured"), "error");
            setClient(null);
            setRole("agent");
        });
    };
    const deleteUserClient = () => {
        if (!client)
            return;
        setIsLoading(true);
        api.DELETE_userFromClient(client.id, user.global_id)
            .then(() => {
            setIsLoading(false);
            refreshUserClients();
            alert.show(t("users.user-clients.user-removed"), "warning");
            refreshCurrentClients(user);
        })
            .catch(error => {
            setIsLoading(false);
            console.error(error);
            alert.show(t("users.user-clients.removing-error-occured"), "error");
        })
            .finally(() => {
            setClient(null);
            setRole("agent");
        });
    };
    const refreshCurrentClients = user => {
        var _a;
        if ((user === null || user === void 0 ? void 0 : user.global_id) !== ((_a = auth.user) === null || _a === void 0 ? void 0 : _a.global_id))
            return;
        setTimeout(() => {
            alert.hide();
            tenancy.currentUserClients();
        }, 1000);
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                    paddingTop: 2,
                    marginBottom: 2,
                } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                            marginRight: 2,
                                        } }, { children: _jsx(ClientsIcon, { fontSize: "large" }) })), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: t("clients.clients") })), _jsx(Typography, Object.assign({ color: "textSecondary" }, { children: t("users.user-clients.user-clients") }))] })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 8 }, { children: _jsx(Table, { title: t("users.user-clients.user-clients"), actions: [
                                    {
                                        icon: () => _jsx(RefreshIcon, { color: "action" }),
                                        tooltip: t("users.user-clients.refresh"),
                                        isFreeAction: true,
                                        onClick: () => {
                                            refreshUserClients();
                                        },
                                    },
                                    {
                                        icon: () => _jsx(NewIcon, { color: "action" }),
                                        tooltip: t("users.user-clients.add-user-to-client"),
                                        isFreeAction: true,
                                        onClick: () => {
                                            setClient(null);
                                            setIsEditOpened(true);
                                            setIsManage(false);
                                            setIsManage(false);
                                        },
                                    },
                                    {
                                        icon: () => _jsx(EditIcon, { color: "action" }),
                                        tooltip: t("clients.client-users.manage-client-user"),
                                        onClick: (event, data) => {
                                            setClient(data);
                                            setRole(data.roles[0].name);
                                            setIsManage(true);
                                            setIsEditOpened(true);
                                        },
                                    },
                                ], columns: isMobile
                                    ? [
                                        {
                                            title: t("clients.client") + " #",
                                            field: "id",
                                            defaultSort: "desc",
                                        },
                                    ]
                                    : [
                                        {
                                            maxWidth: "80px",
                                            title: "#",
                                            field: "id",
                                            status: "numeric",
                                            defaultSort: "asc",
                                        },
                                        {
                                            title: t("users.user-clients.table.name"),
                                            render: client => {
                                                return (_jsx(Link, Object.assign({ color: "inherit", href: "/administration/clients/" + client.id, onClick: event => {
                                                        event.preventDefault();
                                                        navigate("/administration/clients/" +
                                                            client.id);
                                                    }, underline: "hover" }, { children: client.name })));
                                            },
                                        },
                                        {
                                            title: t("users.role"),
                                            sorting: false,
                                            render: client => {
                                                return client.roles[0] != null ? (_jsx(Chip, { label: client.roles[0].name === "admin"
                                                        ? t("users.roles.admin")
                                                        : client.roles[0].name ===
                                                            "manager"
                                                            ? t("users.roles.manager")
                                                            : client.roles[0].name ===
                                                                "super_admin"
                                                                ? t("users.roles.super-admin")
                                                                : client.roles[0].name === "user"
                                                                    ? t("users.roles.user")
                                                                    : t("users.roles.agent"), size: "small", style: {
                                                        border: "1px solid " +
                                                            (client.roles[0].name === "admin"
                                                                ? "rgb(244, 67, 54, 1)"
                                                                : client.roles[0].name ===
                                                                    "manager"
                                                                    ? "rgb(255, 152, 0, 1)"
                                                                    : client.roles[0].name ===
                                                                        "super_admin"
                                                                        ? "rgb(220, 0, 78, 1)"
                                                                        : "rgb(33, 150, 243, 1)"),
                                                        backgroundColor: client.roles[0].name === "admin"
                                                            ? "rgb(244, 67, 54, 0.2)"
                                                            : client.roles[0].name ===
                                                                "manager"
                                                                ? "rgb(255, 152, 0, 0.2)"
                                                                : client.roles[0].name ===
                                                                    "super_admin"
                                                                    ? "rgb(220, 0, 78, 0.2)"
                                                                    : "rgb(33, 150, 243, 0.2)",
                                                    } })) : ("");
                                            },
                                        },
                                    ], detailPanel: isMobile
                                    ? (client) => {
                                        return (_jsx(Box, Object.assign({ sx: {
                                                paddingX: 2,
                                                paddingY: 3,
                                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("clients.client-attributes.name") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: client.name }))] })), client.roles.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("users.role") })), _jsx(Box, Object.assign({ sx: {
                                                                    paddingTop: 0.5,
                                                                } }, { children: _jsx(Chip, { label: client.roles[0].name ===
                                                                        "admin"
                                                                        ? t("users.roles.admin")
                                                                        : client.roles[0]
                                                                            .name ===
                                                                            "manager"
                                                                            ? t("users.roles.manager")
                                                                            : t("users.roles.agent"), size: "small", style: {
                                                                        backgroundColor: client.roles[0]
                                                                            .name === "admin"
                                                                            ? "#f44336"
                                                                            : client.roles[0]
                                                                                .name ===
                                                                                "manager"
                                                                                ? "#ff9800"
                                                                                : "#2196f3",
                                                                        color: "#fff",
                                                                    } }) }))] })))] })) })));
                                    }
                                    : undefined, data: query => getUserClients(query), isLoading: isLoading, tableRef: tableRef, tableQuery: tableQuery }) }))] })) })), _jsxs(Dialog, Object.assign({ fullWidth: true, maxWidth: "xs", open: isEditOpened, onClose: () => {
                    setIsEditOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: isManage
                            ? t("users.user-clients.manage-user") +
                                (user.first_name ? user.first_name + " " : "") +
                                (user.last_name ? user.last_name + " " : "") +
                                t("users.user-clients.on")
                            : t("users.user-clients.add") +
                                (user.first_name ? user.first_name + " " : "") +
                                (user.last_name ? user.last_name + " " : "") +
                                t("users.user-clients.to-client") }), _jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Autocomplete, { disabled: isManage, options: allClients, getOptionLabel: client => client.name, isOptionEqualToValue: (option, value) => option.id === value.id, getOptionDisabled: option => {
                                            let client = allUserClients.find(client => {
                                                return option.id === client.id;
                                            });
                                            return !!client;
                                        }, value: client, onChange: (event, value) => {
                                            setSearchClients("");
                                            searchClientsRef.current = "";
                                            setClient(value);
                                        }, loading: isEditLoading && isEditLoading2, filterSelectedOptions: true, filterOptions: options => options, noOptionsText: t("users.user-clients.search-clients-by-name"), onInputChange: (event, value) => {
                                            setSearchClients(value);
                                            searchClientsRef.current = value;
                                        }, renderOption: (props, client) => (_jsx("li", Object.assign({}, props, { children: _jsxs(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } }, { children: [_jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "button" }, { children: client.name })) })), _jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                        } }, { children: _jsx(Typography, Object.assign({ variant: "caption", color: "textPrimary" }, { children: client.domain })) }))] }), user.id) }))), renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t("users.user-clients.select-client"), required: true, fullWidth: true, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isEditLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true }, { children: [_jsx(InputLabel, { children: t("users.role") }), _jsxs(Select, Object.assign({ label: t("users.role") + " *", value: role, onChange: event => setRole(event.target.value) }, { children: [_jsx(MenuItem, Object.assign({ value: "admin" }, { children: t("users.roles.admin") })), _jsx(MenuItem, Object.assign({ value: "manager" }, { children: t("users.roles.manager") })), _jsx(MenuItem, Object.assign({ value: "agent" }, { children: t("users.roles.agent") }))] }))] })) }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsEditOpened(false);
                                } }, { children: t("cancel") })), isManage && (_jsx(Button, Object.assign({ style: {
                                    color: "rgb(244, 67, 54)",
                                }, onClick: () => {
                                    setIsDeleteOpened(true);
                                } }, { children: t("users.user-clients.remove-user") }))), _jsx(Button, Object.assign({ disabled: !user || !role, onClick: () => {
                                    isManage ? updateClientUser() : newUserClient();
                                    setIsEditOpened(false);
                                } }, { children: isManage
                                    ? t("clients.client-users.update-user")
                                    : t("users.user-clients.add-user") }))] })] })), _jsxs(Dialog, Object.assign({ open: isDeleteOpened, onClose: () => {
                    setIsDeleteOpened(false);
                } }, { children: [_jsx(DialogTitle, { children: t("users.user-clients.remove-user-from") + (client === null || client === void 0 ? void 0 : client.name) + "?" }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: ((user === null || user === void 0 ? void 0 : user.first_name) ? (user === null || user === void 0 ? void 0 : user.first_name) + " " : "") +
                                ((user === null || user === void 0 ? void 0 : user.last_name) ? (user === null || user === void 0 ? void 0 : user.last_name) + " " : "") +
                                t("users.user-clients.user-remove-message") }) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "inherit", onClick: () => {
                                    setIsDeleteOpened(false);
                                } }, { children: t("cancel") })), _jsx(Button, Object.assign({ onClick: () => {
                                    deleteUserClient();
                                    setIsDeleteOpened(false);
                                    setIsEditOpened(false);
                                } }, { children: t("users.user-clients.remove-user") }))] })] }))] }));
}
