import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatDuration, intervalToDuration, sub } from "date-fns";
import { hr, enUS } from "date-fns/locale";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import CopyContentIcon from "@mui/icons-material/ContentCopy";
import ErrorIcon from "@mui/icons-material/Error";
import CustomFieldsView from "../custom-fields/custom-fields-view";
import TicketInformation from "./ticket-information";
import StatusColumn from "./table-columns/status-column";
import AssigneeColumn from "./table-columns/assignee-column";
import PriorityColumn from "./table-columns/priority-column";
import GroupColumn from "./table-columns/group-column";
import { useAlert } from "../../../services/alert";
import { useRoles } from "../../../services/roles";
import { useTenancy } from "../../../services/tenancy";
import { usePrefs } from "../../../services/prefs";
import { copy } from "../../../helpers/copy";
import { useApiTenant } from "../../../api/api-tenant";
export default function TicketDetailsTable({ ticketId, campaign, type, isPreview, refreshTableData, setTicketModalId, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    const { t } = useTranslation();
    const api = useApiTenant();
    const alert = useAlert();
    const roles = useRoles();
    const tenancy = useTenancy();
    const navigate = useNavigate();
    const prefs = usePrefs();
    const locales = { enUS, hr };
    const [ticket, setTicket] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const getTicketData = () => {
        setIsLoading(true);
        api.GET_ticket(ticketId)
            .then(response => {
            if (!response.data && !response.data.ticket)
                return;
            setTicket(response.data.ticket);
            setIsLoading(false);
        })
            .catch(error => {
            if (!api.isCancel(error)) {
                console.error(error);
                setIsLoading(false);
            }
        });
    };
    const refreshTicketData = () => {
        var _a;
        if (campaign &&
            ((_a = campaign.column_display_options) === null || _a === void 0 ? void 0 : _a.some(option => option.relation === "ticketInformation"))) {
            refreshTableData();
            return;
        }
        getTicketData();
        setTicketModalId(null);
    };
    useEffect(() => {
        getTicketData();
        return () => api.cancel();
    }, []);
    return (_jsx(Fragment, { children: !isLoading && ticket ? (_jsxs(Fragment, { children: [type !== "contact" && type !== "history" && (_jsx(Fragment, { children: process.env.APP_ID === "mcd" && roles.isAgent ? null : (_jsx(Fragment, { children: ((ticket.campaign &&
                            ticket.campaign.field_options.length > 0 &&
                            ticket.campaign.field_options.some(e => e.visible &&
                                e.table === "contacts" &&
                                e.data !== "phones_or_emails")) ||
                            ticket.contact.custom_fields.length > 0) && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                        paddingBottom: 2,
                                    } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("contacts.contact") })) })), ticket.contact.phones.length === 0 &&
                                    !ticket.contact.first_name &&
                                    !ticket.contact.last_name &&
                                    !ticket.contact.company &&
                                    !ticket.contact.type &&
                                    ticket.contact.emails.length === 0 &&
                                    !ticket.contact.oib &&
                                    !ticket.contact.address &&
                                    ticket.contact.custom_fields.length === 0 && (_jsx(Box, Object.assign({ sx: { paddingBottom: 3 } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("contacts.contacts-table.no-information") })) }))), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [ticket.contact.phones.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_a = ticket.campaign.field_options.find(a => a.data === "phones")) === null || _a === void 0 ? void 0 : _a.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: t("contacts.contact-attributes.phones") })), ticket.contact.phones.map(phone => {
                                                    var _a;
                                                    return (_jsx(Box, Object.assign({ sx: {
                                                            paddingY: 0.5,
                                                        } }, { children: _jsx(Chip, { style: {
                                                                color: ticket.campaign &&
                                                                    ticket.campaign
                                                                        .field_options &&
                                                                    ticket.campaign
                                                                        .field_options
                                                                        .length > 0 &&
                                                                    ((_a = ticket.campaign.field_options.find(a => a.data ===
                                                                        "phones")) === null || _a === void 0 ? void 0 : _a.visible) === false
                                                                    ? "#bebebe"
                                                                    : phone.on_central_blacklist
                                                                        ? "#f44336"
                                                                        : phone.on_tenant_blacklist
                                                                            ? "#ff9800"
                                                                            : "inherit",
                                                            }, variant: "outlined", size: "small", label: phone.phone_area
                                                                .phone_country
                                                                .phone !== "00"
                                                                ? phone.phone_area
                                                                    .phone_country
                                                                    .phone +
                                                                    " " +
                                                                    phone.phone_area
                                                                        .phone +
                                                                    " " +
                                                                    phone.number.substring(0, 4) +
                                                                    " " +
                                                                    phone.number.substring(4, phone.number
                                                                        .length)
                                                                : phone.number, onDelete: () => {
                                                                copy(phone.phone_area
                                                                    .phone_country
                                                                    .phone !== "00"
                                                                    ? phone
                                                                        .phone_area
                                                                        .phone_country
                                                                        .phone +
                                                                        phone
                                                                            .phone_area
                                                                            .phone +
                                                                        phone.number
                                                                    : phone.number);
                                                                alert.show(t("phone-copied"), "info");
                                                            }, icon: phone.on_central_blacklist ||
                                                                phone.on_tenant_blacklist ? (_jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                                // @ts-ignore
                                                                title: phone.on_central_blacklist
                                                                    ? t("contacts.contact-phones.phone-blacklisted") +
                                                                        " (HAKOM)"
                                                                    : t("contacts.contact-phones.phone-blacklisted") }, { children: _jsx(ErrorIcon, { style: {
                                                                        color: phone.on_central_blacklist
                                                                            ? "#f44336"
                                                                            : "#ff9800",
                                                                    } }) }))) : undefined, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                                // @ts-ignore
                                                                title: t("copy-phone") }, { children: _jsx(CopyContentIcon, {}) })) }) }), phone.id));
                                                })] }))), ticket.contact.emails.length > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_b = ticket.campaign.field_options.find(a => a.data === "emails")) === null || _b === void 0 ? void 0 : _b.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: t("contacts.contact-attributes.emails") })), ticket.contact.emails.map(email => {
                                                    var _a;
                                                    return (_jsx(Box, Object.assign({ sx: {
                                                            display: "flex",
                                                            paddingY: 0.5,
                                                        } }, { children: _jsx(Chip, { style: {
                                                                display: "flex",
                                                                overflow: "hidden",
                                                                color: ticket.campaign &&
                                                                    ticket.campaign
                                                                        .field_options &&
                                                                    ticket.campaign
                                                                        .field_options
                                                                        .length > 0 &&
                                                                    ((_a = ticket.campaign.field_options.find(a => a.data ===
                                                                        "emails")) === null || _a === void 0 ? void 0 : _a.visible) === false
                                                                    ? "#bebebe"
                                                                    : "inherit",
                                                            }, variant: "outlined", size: "small", label: email.email, onDelete: () => {
                                                                copy(email.email);
                                                                alert.show(t("email-copied"), "info");
                                                            }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                                // @ts-ignore
                                                                title: t("copy-email") }, { children: _jsx(CopyContentIcon, {}) })) }) }), email.id));
                                                })] }))), ticket.contact.first_name && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_c = ticket.campaign.field_options.find(a => a.data === "first_name")) === null || _c === void 0 ? void 0 : _c.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: t("contacts.contact-attributes.first-name") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_d = ticket.campaign.field_options.find(a => a.data === "first_name")) === null || _d === void 0 ? void 0 : _d.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: ticket.contact.first_name }))] }))), ticket.contact.last_name && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_e = ticket.campaign.field_options.find(a => a.data === "last_name")) === null || _e === void 0 ? void 0 : _e.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: t("contacts.contact-attributes.last-name") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_f = ticket.campaign.field_options.find(a => a.data === "last_name")) === null || _f === void 0 ? void 0 : _f.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: ticket.contact.last_name }))] }))), ticket.contact.company && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_g = ticket.campaign.field_options.find(a => a.data === "company")) === null || _g === void 0 ? void 0 : _g.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: t("contacts.contact-attributes.company") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_h = ticket.campaign.field_options.find(a => a.data === "company")) === null || _h === void 0 ? void 0 : _h.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: ticket.contact.company }))] }))), ticket.contact.type && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_j = ticket.campaign.field_options.find(a => a.data === "type")) === null || _j === void 0 ? void 0 : _j.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: t("contacts.contact-attributes.type.type") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_k = ticket.campaign.field_options.find(a => a.data === "type")) === null || _k === void 0 ? void 0 : _k.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: ticket.contact.type === "business"
                                                        ? t("contacts.contact-attributes.type.business")
                                                        : t("contacts.contact-attributes.type.person") }))] }))), ticket.contact.address && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_l = ticket.campaign.field_options.find(a => a.data === "address")) === null || _l === void 0 ? void 0 : _l.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: t("contacts.contact-attributes.address") })), _jsx(Box, Object.assign({ sx: {
                                                        paddingY: 0.5,
                                                    } }, { children: _jsx(Link, Object.assign({ style: {
                                                            color: ticket.campaign &&
                                                                ticket.campaign
                                                                    .field_options &&
                                                                ticket.campaign
                                                                    .field_options.length >
                                                                    0 &&
                                                                ((_m = ticket.campaign.field_options.find(a => a.data === "address")) === null || _m === void 0 ? void 0 : _m.visible) === false
                                                                ? "#bebebe"
                                                                : "primary",
                                                        }, href: `https://www.google.com/maps/search/?api=1&query=${encodeURI(((_o = ticket.contact.address
                                                            .street_name) !== null && _o !== void 0 ? _o : "") +
                                                            (ticket.contact.address
                                                                .street_name &&
                                                                ticket.contact.address
                                                                    .street_number
                                                                ? ` ${ticket.contact.address.street_number}`
                                                                : "") +
                                                            (ticket.contact.address
                                                                .street_name &&
                                                                ticket.contact.address.city
                                                                ? `, ${ticket.contact.address.city}`
                                                                : "") +
                                                            (!ticket.contact.address
                                                                .street_name &&
                                                                ticket.contact.address.city
                                                                ? ticket.contact.address
                                                                    .city
                                                                : "") +
                                                            ((ticket.contact.address
                                                                .street_name ||
                                                                ticket.contact.address
                                                                    .city) &&
                                                                ticket.contact.address
                                                                    .country
                                                                ? `, ${ticket.contact.address.country}`
                                                                : "") +
                                                            (!ticket.contact.address
                                                                .street_name &&
                                                                !ticket.contact.address
                                                                    .city &&
                                                                ticket.contact.address
                                                                    .country
                                                                ? ticket.contact.address
                                                                    .country
                                                                : ""))}`, target: "_blank", underline: "hover" }, { children: ((_p = ticket.contact.address
                                                            .street_name) !== null && _p !== void 0 ? _p : "") +
                                                            (ticket.contact.address
                                                                .street_name &&
                                                                ticket.contact.address
                                                                    .street_number
                                                                ? ` ${ticket.contact.address.street_number}`
                                                                : "") +
                                                            (ticket.contact.address
                                                                .street_name &&
                                                                ticket.contact.address.city
                                                                ? `, ${ticket.contact.address.city}`
                                                                : "") +
                                                            (!ticket.contact.address
                                                                .street_name &&
                                                                ticket.contact.address.city
                                                                ? ticket.contact.address
                                                                    .city
                                                                : "") +
                                                            ((ticket.contact.address
                                                                .street_name ||
                                                                ticket.contact.address
                                                                    .city) &&
                                                                ticket.contact.address.country
                                                                ? `, ${ticket.contact.address.country}`
                                                                : "") +
                                                            (!ticket.contact.address
                                                                .street_name &&
                                                                !ticket.contact.address.city &&
                                                                ticket.contact.address.country
                                                                ? ticket.contact.address
                                                                    .country
                                                                : "") })) }))] }))), ticket.contact.oib && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options
                                                                .length > 0 &&
                                                            ((_q = ticket.campaign.field_options.find(a => a.data === "oib")) === null || _q === void 0 ? void 0 : _q.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: "OIB" })), _jsx(Box, Object.assign({ sx: {
                                                        paddingY: 0.5,
                                                    } }, { children: _jsx(Chip, { variant: "outlined", size: "small", style: {
                                                            color: ticket.campaign &&
                                                                ticket.campaign
                                                                    .field_options &&
                                                                ticket.campaign
                                                                    .field_options.length >
                                                                    0 &&
                                                                ((_r = ticket.campaign.field_options.find(a => a.data === "oib")) === null || _r === void 0 ? void 0 : _r.visible) === false
                                                                ? "#bebebe"
                                                                : "inherit",
                                                        }, label: ticket.contact.oib, onDelete: () => {
                                                            copy(ticket.contact.oib);
                                                            alert.show(t("oib-copied"), "info");
                                                        }, deleteIcon: _jsx(Tooltip, Object.assign({ arrow: true, placement: "top", 
                                                            // @ts-ignore
                                                            title: t("copy-oib") }, { children: _jsx(CopyContentIcon, {}) })) }) }))] })))] })), _jsx(Box, Object.assign({ sx: {
                                        paddingTop: 4,
                                        paddingBottom: 3,
                                    } }, { children: _jsx(Divider, { light: true }) })), ticket.contact.custom_fields.length > 0 && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                                marginTop: ticket.contact.phones.length ===
                                                    0 &&
                                                    !ticket.contact.first_name &&
                                                    !ticket.contact.last_name &&
                                                    !ticket.contact.company &&
                                                    !ticket.contact.type &&
                                                    ticket.contact.emails.length ===
                                                        0 &&
                                                    !ticket.contact.oib &&
                                                    !ticket.contact.address
                                                    ? 2
                                                    : 0,
                                            } }, { children: _jsx(CustomFieldsView, { status: "table_view", type: "contacts", customFields: ticket.contact.custom_fields, campaign: ticket.campaign }) })), _jsx(Box, Object.assign({ sx: {
                                                paddingTop: 4,
                                                paddingBottom: 3,
                                            } }, { children: _jsx(Divider, { light: true }) }))] }))] })) })) })), ((ticket.chosen_steps && ticket.chosen_steps.length > 0) ||
                    (ticket.information && ticket.information.length > 0)) && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 2 } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("tickets.ticket-steps.information") })) })), _jsx(TicketInformation, { status: "table_view", ticket: ticket, refreshTicketData: type !== "contact" && type !== "history"
                                ? refreshTicketData
                                : undefined }), _jsx(Box, Object.assign({ sx: {
                                paddingTop: 4,
                                paddingBottom: 3,
                            } }, { children: _jsx(Divider, { light: true }) }))] })), ticket.ticket_detail && (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: {
                                paddingBottom: 2,
                            } }, { children: _jsx(Typography, Object.assign({ variant: "overline" }, { children: t("details") })) })), _jsx(Box, Object.assign({ sx: {
                                paddingBottom: 1,
                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [ticket.ticket_detail.status && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("tickets.ticket-attributes.status") })), _jsx(Box, Object.assign({ sx: {
                                                    paddingY: 0.5,
                                                } }, { children: _jsx(StatusColumn, { type: type, ticket: ticket, ticketStatus: ticket.ticket_detail.status, isPreview: isPreview, refreshTableData: refreshTableData }) }))] }))), !(type === "user" || type === "assigned") && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_s = ticket.campaign.field_options.find(a => a.data === "assignee_ids")) === null || _s === void 0 ? void 0 : _s.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: t("tickets.ticket-attributes.assignee") })), _jsx(Box, Object.assign({ sx: {
                                                    paddingY: 0.5,
                                                } }, { children: _jsx(AssigneeColumn, { type: type, ticket: ticket, ticketAssignees: ticket.ticket_detail.assignees, isPreview: isPreview, refreshTableData: refreshTableData }) }))] }))), ticket.ticket_detail.priority && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("tickets.ticket-attributes.priority") })), _jsx(Box, Object.assign({ sx: {
                                                    paddingY: 0.5,
                                                } }, { children: _jsx(PriorityColumn, { type: type, ticket: ticket, ticketPriority: ticket.ticket_detail.priority, isPreview: isPreview, refreshTableData: refreshTableData }) }))] }))), ((ticket.groups && ticket.groups.length > 0) ||
                                        (ticket.ticket_detail.groups &&
                                            ticket.ticket_detail.groups.length > 0)) && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("tickets.ticket-attributes.groups") })), _jsx(GroupColumn, { ticket: ticket })] }))), type !== "campaign" && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("campaigns.campaign") })), _jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/campaigns/" + ticket.campaign.id), onClick: event => {
                                                    event.preventDefault();
                                                    navigate(tenancy.link("/campaigns/" + ticket.campaign.id));
                                                }, underline: "hover" }, { children: ticket.campaign.name }))] }))), ticket.audience && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("tickets.ticket-attributes.audience") })), roles.isAdmin || roles.isManager ? (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/campaigns/" +
                                                    ticket.campaign.id +
                                                    "/audiences/" +
                                                    ticket.audience.id), onClick: event => {
                                                    event.preventDefault();
                                                    navigate(tenancy.link("/campaigns/" +
                                                        ticket.campaign.id +
                                                        "/audiences/" +
                                                        ticket.audience.id));
                                                }, underline: "hover" }, { children: ticket.audience.name }))) : (_jsx(Typography, { children: ticket.audience.name }))] }))), ticket.ticket_detail.start_date_time && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_t = ticket.campaign.field_options.find(a => a.data === "start_date_time")) === null || _t === void 0 ? void 0 : _t.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: t("tickets.ticket-attributes.start-date") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_u = ticket.campaign.field_options.find(a => a.data === "start_date_time")) === null || _u === void 0 ? void 0 : _u.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: new Date(Date.parse(ticket.ticket_detail.start_date_time)).toLocaleDateString("en-GB") +
                                                    " " +
                                                    new Date(Date.parse(ticket.ticket_detail.start_date_time)).toLocaleTimeString("hr-HR", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }) }))] }))), ticket.ticket_detail.due_date_time && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_v = ticket.campaign.field_options.find(a => a.data === "due_date_time")) === null || _v === void 0 ? void 0 : _v.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: t("tickets.ticket-attributes.due-date") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_w = ticket.campaign.field_options.find(a => a.data === "due_date_time")) === null || _w === void 0 ? void 0 : _w.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: new Date(Date.parse(ticket.ticket_detail.due_date_time)).toLocaleDateString("en-GB") +
                                                    " " +
                                                    new Date(Date.parse(ticket.ticket_detail.due_date_time)).toLocaleTimeString("hr-HR", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }) }))] }))), ticket.ticket_detail.estimate && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_x = ticket.campaign.field_options.find(a => a.data === "estimate")) === null || _x === void 0 ? void 0 : _x.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: t("tickets.ticket-attributes.estimated-time") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_y = ticket.campaign.field_options.find(a => a.data === "estimate")) === null || _y === void 0 ? void 0 : _y.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: formatDuration(intervalToDuration({
                                                    start: sub(new Date(), {
                                                        seconds: ticket.ticket_detail.estimate,
                                                    }),
                                                    end: new Date(),
                                                }), {
                                                    locale: prefs.lang === "en"
                                                        ? locales["enUS"]
                                                        : locales["hr"],
                                                }) }))] }))), ticket.ticket_detail.progress > 0 && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_z = ticket.campaign.field_options.find(a => a.data === "progress")) === null || _z === void 0 ? void 0 : _z.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: t("tickets.ticket-attributes.progress") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                    color: ticket.campaign &&
                                                        ticket.campaign.field_options &&
                                                        ticket.campaign.field_options.length > 0 &&
                                                        ((_0 = ticket.campaign.field_options.find(a => a.data === "progress")) === null || _0 === void 0 ? void 0 : _0.visible) === false
                                                        ? "#bebebe"
                                                        : "inherit",
                                                } }, { children: ticket.ticket_detail.progress + "%" }))] }))), ticket.ticket_detail.notes && (_jsx(Fragment, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 6 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options.length >
                                                                0 &&
                                                            ((_1 = ticket.campaign.field_options.find(a => a.data === "notes" &&
                                                                a.table === "ticket_details")) === null || _1 === void 0 ? void 0 : _1.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: t("tickets.ticket-attributes.notes") })), _jsx(Typography, Object.assign({ variant: "body2", style: {
                                                        color: ticket.campaign &&
                                                            ticket.campaign.field_options &&
                                                            ticket.campaign.field_options.length >
                                                                0 &&
                                                            ((_2 = ticket.campaign.field_options.find(a => a.data === "notes" &&
                                                                a.table === "ticket_details")) === null || _2 === void 0 ? void 0 : _2.visible) === false
                                                            ? "#bebebe"
                                                            : "inherit",
                                                    } }, { children: ticket.ticket_detail.notes }))] })) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("created-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(ticket.created_at)).toLocaleDateString("en-GB") +
                                                    " " +
                                                    new Date(Date.parse(ticket.created_at)).toLocaleTimeString("hr-HR", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }) }))] })), ticket.created_by && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("tickets.ticket-attributes.created-by") })), roles.isAdmin || roles.isManager ? (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" + ticket.created_by.id), onClick: event => {
                                                    event.preventDefault();
                                                    navigate(tenancy.link("/settings/users/" +
                                                        ticket.created_by.id));
                                                }, underline: "hover" }, { children: ticket.created_by.first_name +
                                                    (ticket.created_by.last_name
                                                        ? " " + ticket.created_by.last_name
                                                        : "") }))) : (_jsx(Typography, Object.assign({ variant: "body2" }, { children: ticket.created_by.first_name +
                                                    (ticket.created_by.last_name
                                                        ? " " + ticket.created_by.last_name
                                                        : "") })))] }))), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("updated-at") })), _jsx(Typography, Object.assign({ variant: "body2" }, { children: new Date(Date.parse(ticket.updated_at)).toLocaleDateString("en-GB") +
                                                    " " +
                                                    new Date(Date.parse(ticket.updated_at)).toLocaleTimeString("hr-HR", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }) }))] })), ticket.updated_by && (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: process.env.APP_ID === "mcd" ? 12 : 3 }, { children: [_jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: t("tickets.ticket-attributes.updated-by") })), roles.isAdmin || roles.isManager ? (_jsx(Link, Object.assign({ color: "inherit", href: tenancy.link("/settings/users/" + ticket.updated_by.id), onClick: event => {
                                                    event.preventDefault();
                                                    navigate(tenancy.link("/settings/users/" +
                                                        ticket.updated_by.id));
                                                }, underline: "hover" }, { children: ticket.updated_by.first_name +
                                                    (ticket.updated_by.last_name
                                                        ? " " + ticket.updated_by.last_name
                                                        : "") }))) : (_jsx(Typography, Object.assign({ variant: "body2" }, { children: ticket.updated_by.first_name +
                                                    (ticket.updated_by.last_name
                                                        ? " " + ticket.updated_by.last_name
                                                        : "") })))] })))] })) }))] }))] })) : (_jsx(Box, Object.assign({ sx: {
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
            } }, { children: _jsx(CircularProgress, { size: 20 }) }))) }));
}
